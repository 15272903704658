import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { CSSTransitionGroup } from "react-transition-group";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useHistory, Link } from "react-router-dom";
import ContentLoading from "src/Components/Loading/contentLoading";
import DataTableControlled from "src/Components/DataTableControlled";
import PageTitle from "src/Layout/AppMain/PageTitle";
import {
  reportParameters,
  reportDetails,
  mockFormat,
} from "src/Pages/Report/NewReport/ReportsList/mockData";
import {
  restApiRequest,
  REPORT_SERVICE,
  getServiceHost,
  getFileNameFromResponse,
  saveFile,
} from "src/utils/Api";
import isMockView from "src/utils/Api/isMockView";
import { getSession } from "src/utils/RoleBasedSecurity/Session";
import { dynamicNotification } from "src/utils/Notifications";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { getAhrUrl } from "src/Pages/Report/helpers/ahrHelper";
import __ from "src/utils/Translations";
import { getLanguage } from "src/utils/Languages/LanguageContext";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import {
  SetSessionValue,
  GetSessionValue,
} from "src/Pages/Report/helpers/sessionHelper";
import uuid from "src/utils/jsHelpers/uuid";
import Spinner from "src/Components/Spinner";
import Subscription from "./Subscription";
import Parameters from "./parameters";
import Panel from "./panel";

const ReportDetail = ({
  archiveUid,
  selectedCompanyId,
  companyName,
  reportId,
  breadcrumbs,
}) => {
  const [parametersState, setParametersState] = useState([]);
  const [fetchedParameters, setFetchedParameters] = useState([]);
  const [reportDetail, setReportDetail] = useState({});
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [generatedReport, setGeneratedReport] = useState({
    headerColumns: [],
    data: [],
    totalItems: 0,
    reportTitle: "",
    reportFooter: "",
    reportCodeName: "",
  });
  const [formatOptions, setFormatOptions] = useState([]);
  const [isGeneratedReportLoading, setIsGeneratedReportLoading] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [savedSubPopup, setSavedSubPopup] = useState(false);
  const [isGetBlockBtn, setIsGetBlockBtn] = useState(false);
  const [invalidParameters, setInvalidParameters] = useState([]);
  const [fetchedDynamicParameters, setFetchedDynamicParameters] = useState([]);
  const [isSetSubscribe, setIsSetSubscribe] = useState(false);
  const [adHocState, setAdHocState] = useState({});
  const [isAgrAvailable, setAgrAvailable] = useState({});
  const history = useHistory();

  const [reportDetailsLoaded, setReportDetailsLoaded] = useState(false);
  const [parametersLoaded, setParametersLoaded] = useState(false);
  const [parametersRenderCompleted, setParametersRenderCompleted] =
    useState(false);

  const rbsContext = useContext(RbsContext);
  const { userInfo } = rbsContext;
  const isAhr = userInfo.isAhr();

  const initialRecipients = isAhr
    ? [{ label: userInfo.getEmail(), value: userInfo.getEmail() }]
    : [];
  let paramAhr = false;

  useEffect(() => {
    setEmptyState();
    LoadParamAhr(reportId);
    fetchReportDetails(reportId);
    fetchParameters(reportId);
    fetchDynamicParameters(reportId);
    getCompanyLanguages(selectedCompanyId);
    getAgrAvailable(selectedCompanyId);
    // eslint-disable-next-line
  }, [reportId]);

  useEffect(() => {
    const pArr = [];

    fetchedParameters.map((item) => {
      let defval =
        typeof item.defaultValue !== "undefined" ? item.defaultValue : [];
      if (item.type === "Select") {
        const asd = item.selectOptions.find(
          (a) => a.value === item.defaultValue[0]
        );
        defval = asd;
      } else if (item.type === "Multiselect") {
        const valsArray = [];
        item.defaultValue.forEach((val) => {
          const selectedVal = item.selectOptions.find((b) => b.value === val);
          if (typeof selectedVal !== "undefined" && selectedVal != null) {
            valsArray.push(selectedVal);
          }
        });
        defval = valsArray;
      }
      pArr.push({
        parameterName: item.parameterName,
        values: defval,
        label: item.label,
      });
      return "";
    });
    setParametersState(pArr);
  }, [fetchedParameters]);

  useEffect(() => {
    setIsGeneratedReportLoading(false);
  }, [generatedReport]);

  useEffect(() => {
    if (invalidParameters.length === 0) {
      setIsGetBlockBtn(false);
    } else {
      setIsGetBlockBtn(true);
    }
  }, [invalidParameters]);

  const setEmptyState = () => {
    setAdHocState((prev) => ({
      ...prev,
      isTranslationChecked: false,
      availableColumns: [],
      selectedColumns: [],
      namePl: "",
      nameEn: "",
    }));
  };

  const fetchParameters = (id) => {
    setParametersRenderCompleted(false);
    LoadParamAhr(reportId);
    fetchData(
      setFetchedParameters,
      archiveUid === undefined
        ? `/reports/parameters/${id}/${selectedCompanyId}/?isAhr=${paramAhr}`
        : `/reports/parameters/${id}/${selectedCompanyId}/${archiveUid}`,
      REPORT_SERVICE,
      "GET",
      {},
      reportParameters,
      __("Błąd podczas pobierania parametrów")
    ).then(() => {
      setParametersLoaded(true);
    });
  };

  const fetchDynamicParameters = (id) => {
    setParametersRenderCompleted(false);
    fetchData(
      setFetchedDynamicParameters,
      `/reports/parameters/dynamic/${id}/?companyId=${selectedCompanyId}`,
      REPORT_SERVICE,
      "GET",
      {},
      null,
      __("Błąd podczas pobierania dynamicznych parametrów")
    ).then(() => {
      setParametersLoaded(true);
    });
  };

  const closeSubPopup = () => setSavedSubPopup(false);
  const fetchReportDetails = (id) => {
    fetchData(
      (result) => {
        setReportDetail(result);
        fetchGetAdHocReport(reportId, result.metadataId);
      },
      `/reports/${id}/${selectedCompanyId}`,
      REPORT_SERVICE,
      "GET",
      {
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      reportDetails,
      __("Błąd podczas pobierania danych raportu")
    ).then((result) => {
      setReportDetailsLoaded(true);
    });
  };

  const getCompanyLanguages = (selectedCompany) => {
    if (
      selectedCompany !== "" &&
      selectedCompany !== "00000000-0000-0000-0000-000000000000"
    ) {
      // check session
      fetchData(
        (result) => {
          if (result !== undefined && result !== null) {
            SetSessionValue(`languages-${selectedCompany}`, result);
            const englishEnabled =
              result.find((x) => x.code === "en") !== undefined;
            SetSessionValue("englishEnabled", englishEnabled);
          } else {
            SetSessionValue(`languages-${selectedCompany}`, [
              { code: "pl", name: "polski" },
            ]);
            SetSessionValue("englishEnabled", false);
          }
        },
        `/company/languages/${selectedCompany}`,
        REPORT_SERVICE,
        "GET",
        {
          headers: {
            "Accept-Language": getLanguage(),
          },
        },
        null,
        __("Błąd podczas pobierania danych raportu")
      ).then((result) => {});
    }
  };

  const getAgrAvailable = (selectedCompany) => {
    if (
      selectedCompany !== "" &&
      selectedCompany !== "00000000-0000-0000-0000-000000000000"
    ) {
      // check session
      fetchData(
        (result) => {
          setAgrAvailable(result);
        },
        `/company/agr/${selectedCompany}`,
        REPORT_SERVICE,
        "GET",
        {
          headers: {
            "Accept-Language": getLanguage(),
          },
        },
        reportDetails,
        __("Błąd podczas pobierania danych raportu")
      ).then((result) => {});
    }
  };

  const checkDates = () => {
    // płacowy, grup dochodowości, zgód do świadczeń, historii doładowań
    const datefrom = parametersState.find(
      (elem) => elem.parameterName === "StartDate"
    );
    const dateto = parametersState.find(
      (elem) => elem.parameterName === "EndDate"
    );
    // banków punktów
    // cech pracowników
    const reportDate = parametersState.find(
      (elem) => elem.parameterName === "ReportDate"
    );
    // przystąpień/rezygnacji
    // kto zrezygnował ze świadczeń
    // dedykowany raport banków punktów
    // płacowy Westrock

    if (datefrom && datefrom.values[0] === null) {
      dynamicNotification(__("Uzupelnij pole 'Data od'"), "error");
      if (dateto && dateto.values[0] === null) {
        dynamicNotification(__("Uzupelnij pole 'Data do'"), "error");
      }
      return false;
    }
    if (dateto && dateto.values[0] === null) {
      dynamicNotification(__("Uzupelnij pole 'Data do'"), "error");
      return false;
    }
    return true;
  };

  const checkDateRange = (isExportToFile) => {
    // płacowy, grup dochodowości, zgód do świadczeń, historii doładowań
    const isFound = parametersState.find((elem) => {
      if (
        elem.parameterName === "StartDate" ||
        elem.parameterName === "SubscriptionStartDate" ||
        elem.parameterName === "PaymentStartDate"
      ) {
        return true;
      }
      return false;
    });
    if (isFound) {
      const datefrom = parametersState.find(
        (elem) =>
          elem.parameterName === "StartDate" ||
          elem.parameterName === "SubscriptionStartDate" ||
          elem.parameterName === "PaymentStartDate"
      );
      const dateTo = parametersState.find(
        (elem) =>
          elem.parameterName === "EndDate" ||
          elem.parameterName === "SubscriptionEndDate" ||
          elem.parameterName === "PaymentEndDate"
      );

      const dateDiff =
        new Date(dateTo.values[0]).getTime() -
        new Date(datefrom.values[0]).getTime();
      const diffDays = dateDiff / (1000 * 3600 * 24);

      const maxDaysLimit = 365;
      if (isAhr && diffDays > maxDaysLimit) {
        dynamicNotification(
          __(
            "Ze względu na wybrany długi okres dla raportu prosimy o zlecenie jego przygotowania poprzez kontakt z opiekunem."
          ),
          "info",
          "top-right",
          10000
        );
        return false;
      }
      return true;
    }

    // banków punktów
    // cech pracowników
    const reportDate = parametersState.find(
      (elem) => elem.parameterName === "ReportDate"
    );
    // przystąpień/rezygnacji
    // kto zrezygnował ze świadczeń
    // dedykowany raport banków punktów
    // płacowy Westrock

    return true;
  };

  const fetchReportData = (filters, page, pageSize, sort) => {
    if (!checkDates() || !checkDateRange(false)) {
      return;
    }
    loadReportData(reportId, selectedCompanyId, page, pageSize);
  };

  const fetchReportDataBtn = (filters, page, pageSize, sort) => {
    if (isReportGenerated) {
      fetchReportData(filters, page, pageSize, sort);
    } else {
      setIsReportGenerated(true);
    }
  };

  const loadReportData = (
    selectedReportId,
    companyId,
    page = 1,
    pageSize = 20
  ) => {
    setIsGeneratedReportLoading(true);
    fetchData(
      (result) => {
        generatedReport.headerColumns = result.headerColumns;
        generatedReport.data = result.data;
        generatedReport.totalItems = result.totalItems;
        generatedReport.reportTitle = result.reportTitle;
        generatedReport.reportFooter = result.reportFooter;
        generatedReport.reportCodeName = result.reportCodeName;
        setIsReportGenerated(true);
      },
      `/reports/data/${selectedReportId}/${companyId}?page=${
        page || 1
      }&pageSize=${pageSize || 20}`,
      REPORT_SERVICE,
      "POST",
      {
        body: getParametersState(parametersState),
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      null,
      __("Błąd podczas generowania podglądu raportu")
    ).then(() => {
      setIsGeneratedReportLoading(false);
    });
  };

  const fetchFormatOptions = () => {
    fetchData(
      setFormatOptions,
      "/reports/static/formats",
      REPORT_SERVICE,
      "GET",
      {},
      mockFormat,
      __("Błąd podczas pobierania dostępnych formatów")
    );
  };

  const fetchGetAdHocReport = (uid, metadataId) => {
    fetchData(
      (result) => {
        fetchBaseAdHocReport(uid, result);
      },
      `/reportconfig/${uid}?langCode=${getLanguage()}`,
      REPORT_SERVICE,
      "GET",
      {
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      null,
      __("Błąd podczas pobierania konfiguracji raportu")
    ).then((result) => {});
  };

  const fetchBaseAdHocReport = (uid, adHocReport = null) => {
    fetchData(
      (result) => {
        const baseCols = result.cols.map((column) => ({
          id: uuid(),
          defaultName: column.col,
          customName: column.pl,
          englishName: column.en,
          defaultPl: column.pl,
          defaultEn: column.en,
        }));

        if (adHocReport.isBase) {
          setAdHocState((prev) => ({
            ...prev,
            dataFromApi: result,
            namePl: result.namePl,
            nameEn: result.nameEn,
            // isTranslationChecked: false,
            availableColumns: baseCols,
            selectedColumns: [],
          }));
        } else {
          try {
            setAdHocState((prev) => ({
              ...prev,
              dataFromApi: adHocReport,
              // isTranslationChecked: false,
              availableColumns: [],
              selectedColumns: adHocReport.cols.map((column) => ({
                id: uuid(),
                defaultName: column.col,
                customName: column.pl,
                englishName: column.en,
                defaultPl: baseCols.some((x) => x.defaultName === column.col)
                  ? baseCols.find((x) => x.defaultName === column.col).defaultPl
                  : column.col,
                defaultEn: baseCols.some((x) => x.defaultName === column.col)
                  ? baseCols.find((x) => x.defaultName === column.col).defaultEn
                  : column.en,
              })),
            }));
          } catch (error) {
            newRelicErrorReport(
              error,
              "Pages/Report/NewReport/ReportsList/Report/index.js - 557"
            );
          }
        }
      },
      `/reportconfig/base/${uid}`,
      REPORT_SERVICE,
      "GET",
      {
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      null,
      __("Błąd podczas Pobierania domyślnej konfiguracji raportu")
    ).then((result) => {});
  };

  const fetchSaveOrCreateAdHocReport = () => {
    const guidToSave = adHocState.dataFromApi.isBase
      ? "00000000-0000-0000-0000-000000000000"
      : reportDetail.uid;
    const endpoint = adHocState.dataFromApi.isBase
      ? "/reportconfig/"
      : `/reportconfig/${guidToSave}`;
    const method = adHocState.dataFromApi.isBase ? "POST" : "PUT";
    fetchData(
      (result) => {
        dynamicNotification("Konfiguracja raportu została zapisana");
        history.push(
          getAhrUrl(
            `/report/predefined-reports/${result.uid}${
              !isAhr ? `/${selectedCompanyId}` : ""
            }`,
            isAhr
          )
        );
      },
      endpoint,
      REPORT_SERVICE,
      method,
      {
        body: {
          ...adHocState.dataFromApi,
          namePl: adHocState.namePl,
          nameEn: adHocState.nameEn,
          uid: guidToSave,
          clientUid: selectedCompanyId,
          cols: getColumnsToSave(),
          metadata: reportDetail.metadataId,
        },
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      null,
      __("Błąd podczas zapisu konfiguracji raportu")
    ).then(() => {});
  };

  function getColumnsToSave() {
    const cols = [];
    adHocState.selectedColumns.forEach((item) => {
      cols.push({
        col: item.defaultName,
        pl: item.customName,
        en: item.englishName,
        sum: false,
      });
    });
    return cols;
  }

  const changeSwitchSendReportFunc = (isOn) => {
    setIsSetSubscribe(isOn);
  };

  const getDisabledParameterFunc = (nameParam) => {
    if (isSetSubscribe === true) {
      const indexParam = fetchedDynamicParameters.findIndex(
        (x) => x.parameterName === nameParam
      );
      if (indexParam >= 0) {
        return true;
      }
    }
    return false;
  };

  function PanelBlockBtnFunc() {
    if (isGetBlockBtn === true) {
      return true;
    }
    if (isSetSubscribe === true) {
      if (fetchedDynamicParameters.length > 0) {
        return true;
      }
    }
    return false;
  }

  const downloadReport = async (format, callback, toArchive) => {
    if (!isMockView()) {
      if (!checkDates() || !checkDateRange(true)) {
        callback(false);
        return;
      }
      const chosenFormat = formatOptions.find(
        (x) => x.value === Number(format)
      );
      const session = getSession();
      const server = getServiceHost(REPORT_SERVICE);
      const url = `${window.reportingTool}${server}/reports/static/file/${chosenFormat.value}`;

      const requestBody = JSON.stringify({
        reportId,
        reportGroupId: reportDetail.groupId,
        companyId: selectedCompanyId,
        parameters: parseParameterState(parametersState),
        toArchive,
      });

      const response = await fetch(url, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "content-language": getLanguage(),
          Authorization: `Bearer ${await session.getValidAccessToken()}`,
          "Accept-Language": getLanguage(),
        },
        body: requestBody,
      });

      if (!response.ok) {
        const responseJson = await response.json();
        dynamicNotification(
          `${responseJson.title}: ${responseJson.detail}`,
          "error",
          "top-right",
          10000
        );
        callback(false);
      } else {
        const fileName = `${reportDetail.name}.${chosenFormat.label}`;
        const blob = await response.blob();
        const resultFileName = fileName || getFileNameFromResponse(response);
        saveFile(blob, false, resultFileName);
        callback(true);
      }
    } else {
      callback(false);
    }
  };

  const requestReport = async (format, callback) => {
    if (!isMockView()) {
      if (!checkDates() || !checkDateRange(true)) {
        callback(false);
        return;
      }
      const chosenFormat = formatOptions.find(
        (x) => x.value === Number(format)
      );
      const session = getSession();
      const server = getServiceHost(REPORT_SERVICE);
      const url = `${window.reportingTool}${server}/reports/filerequest/${chosenFormat.value}`;

      const requestBody = JSON.stringify({
        reportId,
        reportGroupId: reportDetail.groupId,
        companyId: selectedCompanyId,
        parameters: parseParameterState(parametersState),
      });

      const response = await fetch(url, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "content-language": getLanguage(),
          Authorization: `Bearer ${await session.getValidAccessToken()}`,
          "Accept-Language": getLanguage(),
        },
        body: requestBody,
      });

      if (!response.ok) {
        const responseJson = await response.json();
        dynamicNotification(
          `${responseJson.title}: ${responseJson.detail}`,
          "error",
          "top-right",
          10000
        );
        callback(false);
      } else {
        const responseJson = await response.json();
        // eslint-disable-next-line eqeqeq
        if (responseJson == -1) {
          dynamicNotification(
            __(
              "Trwa już proces generowania raportu z wybranymi parametrami Poczekaj aż proces dobiegnie końca."
            ),
            "info",
            "top-right",
            10000
          );
        } else {
          dynamicNotification(
            <QueueToastMessage />,
            "info",
            "top-right",
            10000
          );
        }
        callback(true);
      }
    } else {
      callback(true);
    }
  };

  // eslint-disable-next-line react/prop-types
  const QueueToastMessage = () => (
    <div>
      Raport w trakcie generowania
      <Link to={getAhrUrl(`/report/archive`, isAhr)}>
        <Button role="button" color="link">
          {" "}
          {__("Przejdź do Twoje raporty")}
        </Button>
      </Link>
    </div>
  );

  const handleSubscribeClick = (data) => {
    const requestBody = {
      parameters: parseParameterState(parametersState),
      subscriptionData: data,
      companyId: selectedCompanyId,
    };
    requestBody.subscriptionData.reportId = reportId;
    setIsSaving(true);

    fetchData(
      () => {
        setSavedSubPopup(true);
        history.push("/report/subscriptions");
      },
      "/subscription",
      REPORT_SERVICE,
      "POST",
      {
        body: requestBody,
        headers: {
          "Accept-Language": getLanguage(),
        },
      },
      {},
      __("Błąd podczas zapisywania wysyłki")
    ).then(() => {
      setIsSaving(false);
    });
  };

  const initialSubscribeInfo = {
    note: "",
    subscribeMethod: {
      subscribeMethod: { label: __("Nie wybrano"), value: -1 },
      messageTitle: "",
      recipients: initialRecipients,
      hiddenRecipients: [],
      format: { label: __("Nie wybrano"), value: -1 },
      messageContent: "",
      ahrList: [],
    },
    subscribeFrequency: {
      subscribeFrequency: { label: __("Nie wybrano"), value: -1 },
      sendDate: new Date().toISOString().slice(0, 10),
      sendStartDate: new Date().toISOString().slice(0, 10),
      sendTime: "10:00",
      dayOfWeek: [],
      daysOfMonth: { label: 1, value: 1 },
      daysSendAmount: { label: 1, value: 1 },
      daysToSend: [],
      sendMonthStart: { value: 1, label: "styczeń" },
      repeatEvery: "1",
      occurrence: "",
    },
    subscribeLanguage: getLanguage(),
  };

  const subscribeNode = (
    <Subscription
      companyId={selectedCompanyId}
      subscribeFunc={handleSubscribeClick}
      subscribeData={initialSubscribeInfo}
      reportId={reportId}
      isSaving={isSaving}
      setSubscribeDataToEditSend={() => {}}
      isGetBlockBtn={isGetBlockBtn}
      isAhr={isAhr}
      isdateOK={checkDates}
      changeSwitchSendReport={changeSwitchSendReportFunc}
      parametersDynamic={fetchedDynamicParameters}
    />
  );

  let reportHeading =
    typeof reportDetail.fullName !== "undefined" ? reportDetail.fullName : "";
  if (reportHeading.length === 0) {
    reportHeading =
      typeof reportDetail.name !== "undefined" ? reportDetail.name : "";
  }

  const setInvalidParametersFunc = (d, push) => {
    if (push) {
      if (!invalidParameters.includes(d)) {
        const arr = [...invalidParameters];
        arr.push(d);
        setInvalidParameters(arr);
      }
    } else if (!push && invalidParameters.includes(d)) {
      let arr = [...invalidParameters];
      arr = arr.filter((item) => item !== d);
      setInvalidParameters(arr);
    }
  };

  const setPreParametersState = (Data) => {
    LoadParamAhr(reportId);
    for (let index = 0; index < Data.length; index += 1) {
      const act = Data[index];
      if (typeof act !== "undefined") {
        if (act.parameterName === "pIsAhr") {
          const valueAhrArray = act.values;
          const valueAhr =
            valueAhrArray[0] === true || valueAhrArray[0] === "true";
          if (paramAhr !== valueAhr) {
            paramAhr = valueAhr;
            SaveParamAhr(paramAhr);
            fetchParameters(reportId);
            return;
          }
        }
      }
    }
    setParametersState(Data);
  };

  const LoadParamAhr = (ReportId) => {
    const sessionParamAhr = GetSessionValue("aParamAhr");
    const sessionParamAhrReportId = GetSessionValue("aParamAhrReportId");
    if (typeof sessionParamAhr === "undefined" || sessionParamAhr === null)
      return;
    if (
      typeof sessionParamAhrReportId === "undefined" ||
      sessionParamAhrReportId === null
    )
      return;
    if (sessionParamAhrReportId === ReportId) {
      paramAhr = sessionParamAhr;
    }
  };

  const SaveParamAhr = (AhrValue) => {
    SetSessionValue("aParamAhr", AhrValue);
    SetSessionValue("aParamAhrReportId", reportId);
  };

  return (
    <ContentLoading
      message={Spinner}
      show={
        !parametersLoaded ||
        !parametersRenderCompleted ||
        !reportDetailsLoaded ||
        isGeneratedReportLoading
      }
      messageStyle={{ position: "absolute", top: "20%", right: "50%" }}
      backgroundStyle={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
    >
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={__(reportHeading)}
          pushToHistory
          breadcrumbs={breadcrumbs}
        />
        <div className="report-detail-margin-bottom">
          <div>
            {" "}
            <input
              className="form-control"
              type="text"
              value={companyName}
              disabled
            />
          </div>
          <Panel
            showReport={fetchReportDataBtn}
            download={downloadReport}
            requestReport={requestReport}
            fetchFormatOptions={fetchFormatOptions}
            formatOptions={formatOptions}
            reportName={reportDetail.name || ""}
            fullreportName={reportDetail.fullName || ""}
            isGetBlockBtn={PanelBlockBtnFunc()}
            isAhr={isAhr}
            isdateOK={checkDates}
            adHocState={adHocState}
            handleSaveAdHocReport={fetchSaveOrCreateAdHocReport}
            announcement={reportDetail.announcement || ""}
            isDownloadBtnVisible={!isAgrAvailable}
            isQueueBtnVisible={isAgrAvailable}
          />
        </div>
        <div className="report-detail-margin-bottom">
          <Parameters
            className="report-detail-margin-bottom"
            parameters={fetchedParameters}
            parametersState={parametersState}
            setParametersState={setPreParametersState}
            subscription={subscribeNode}
            setInvalidParameters={setInvalidParametersFunc}
            isGetBlockBtn={isGetBlockBtn}
            disabledParameterFunc={getDisabledParameterFunc}
            showAllSelected
            adHocState={adHocState}
            isAhr={isAhr}
            isCreator={false}
            setAdHocState={setAdHocState}
            setParametersRenderCompleted={setParametersRenderCompleted}
          />
        </div>
        <div className="report-detail-margin-bottom">
          {isReportGenerated && (
            <DataTableControlled
              // key={[]}
              id="generatedReportData"
              fetchData={fetchReportData}
              columns={generatedReport.headerColumns}
              data={generatedReport.data}
              count={generatedReport.totalItems}
              title={generatedReport.reportTitle}
              footer={generatedReport.reportFooter}
              // filterable
            />
          )}
        </div>
      </CSSTransitionGroup>
      <Modal
        isOpen={savedSubPopup}
        toggle={closeSubPopup}
        unmountOnClose
        size="sm"
      >
        <ModalHeader toggle={closeSubPopup}>{__("Powiadomienie")}</ModalHeader>
        <ModalBody>{__("Pomyślnie zapisano wysyłkę")}</ModalBody>
        <ModalFooter>
          <Button color="success" onClick={closeSubPopup}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </ContentLoading>
  );
};

ReportDetail.propTypes = {
  archiveUid: PropTypes.any.isRequired,
  selectedCompanyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};
export default ReportDetail;

const fetchData = async (
  updateData,
  endpoint,
  service,
  method,
  { headers, params, body },
  mockData,
  error
) => {
  if (isMockView()) {
    updateData(mockData);
  } else {
    try {
      const result = await restApiRequest(
        service,
        endpoint,
        method,
        { headers, params, body },
        {}
      );
      updateData(result);
    } catch (e) {
      newRelicErrorReport(
        e,
        "Pages/Report/NewReport/ReportsList/Report/index.js - 557"
      );
      dynamicNotification(
        `${e.error}: ${e.message}` || `${error}` || "błąd",
        "error"
      );
    }
  }
};

const parseParameterState = (params) => {
  const newParams = [];
  params.map((item) => newParams.push({ ...item }));

  for (let i = 0; i < newParams.length; i += 1) {
    const element = newParams[i].values;

    const arr = [];
    if (element.value) {
      arr.push(element);
    }
    if (element.length === 0) {
      arr.push({ label: "", value: "" });
    }

    for (let j = 0; j < element.length; j += 1) {
      const element2 = element[j];

      if (typeof element2.label === "undefined") {
        arr.push({ label: element2, value: element2 });
      } else {
        arr.push(element2);
      }
    }
    newParams[i].values = arr;
  }
  return newParams;
};

const getParametersState = (params) => {
  const newParams = [];
  params.map((item) => newParams.push({ ...item }));

  const parametersArray = [];
  for (let i = 0; i < newParams.length; i += 1) {
    if (Array.isArray(newParams[i].values)) {
      if (newParams[i].values.length === 0) {
        parametersArray.push({
          paramName: newParams[i].parameterName,
          paramValue: null,
        });
      } else if (
        newParams[i].values.length === 1 &&
        !Object.hasOwn(newParams[i].values[0], "label")
      ) {
        parametersArray.push({
          paramName: newParams[i].parameterName,
          paramValue: newParams[i].values[0],
        });
      } else {
        const multiValuesSplit = [];
        for (let j = 0; j < newParams[i].values.length; j += 1) {
          const split = newParams[i].values[j].value.split(",");
          for (let k = 0; k < split.length; k += 1) {
            // eslint-disable-next-line
            multiValuesSplit.push(parseInt(split[k]));
          }
        }

        // eslint-disable-next-line
        parametersArray.push({
          paramName: newParams[i].parameterName,
          // eslint-disable-next-line
          paramValue: multiValuesSplit.join(","),
        });
      }
    } else {
      parametersArray.push({
        paramName: newParams[i].parameterName,
        paramValue: newParams[i].values?.value,
      });
    }
  }
  const result = parametersArray.reduce(
    (obj, item) => Object.assign(obj, { [item.paramName]: item.paramValue }),
    {}
  );
  return result;
};
