import "./polyfills";
import React from "react";
import { createRoot } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import InspectorWrapper from "./devInspector/prod";
import * as serviceWorker from "./serviceWorker";

import "./assets/base.scss";
import Main from "./Pages/Main";
import configureStore from "./config/configureStore";
import getUserConfirmationPopup from "./utils/system/DOM/getUserConfirmationPopup";
import RbsAppWrapper from "./utils/RoleBasedSecurity/RbsAppWrapper";
import ScrollToTop from "./Layout/AppMain/ScrollToTop";
import { getVersion } from "./config/env";
import { FeatureFlagsProvider } from "./utils/FeatureFlags/FeatureFlagsContext";

if (module.hot) {
  module.hot.accept();
}

const VERSION_KEY = "version";
const store = configureStore();
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const featureFlags = {
  apiUrl: process.env.REACT_APP_FEATURE_FLAGS_URL || "",
};

/**
 * @todo It's a temporary solution; it should be removed after feature flags are fully implemented,
 * and OMB will be built into static JS instead of a working container.
 */
const domainRegex =
  // eslint-disable-next-line
  /https:\/\/((?<env>(dv1|qa1))|(?<adm>adm))(?:-(?<dv>[0-9]*).omb.mybenefit.g4n.eu|\.staging\.aws\.mybenefit\.pl|\.dev\.aws\.mybenefit\.pl|-kafeteria(?<envAdm>\.dev\.aws|\.staging\.aws|)\.mybenefit\.pl)/g.exec(
    window.location.href
  );
/**
 * Check if any of:
 *
 * https://adm-kafeteria.dev.aws.mybenefit.pl
 * https://dv1.dev.aws.mybenefit.pl/
 * https://dv1-<number>.omb.mybenefit.g4n.eu
 *
 * https://adm-kafeteria.staging.aws.mybenefit.pl
 * https://qa1.staging.aws.mybenefit.pl
 * https://qa1-<number>.omb.mybenefit.g4n.eu
 *
 * https://adm-kafeteria.mybenefit.pl
 */
window.reportingTool = "https://reports.adm-kafeteria.dev.aws.mybenefit.pl";

// feature-branch
if (typeof domainRegex?.groups?.dv !== "undefined") {
  if (domainRegex?.groups?.env === "dv1") {
    featureFlags.apiUrl =
      "https://status.mybenefit.g4n.eu/?action=getFeatureFlags&env=dv1";
    window.reportingTool = "https://reports.adm-kafeteria.dev.aws.mybenefit.pl";
  } else if (domainRegex?.groups?.env === "qa1") {
    featureFlags.apiUrl =
      "https://status.mybenefit.g4n.eu/?action=getFeatureFlags&env=qa1";
    window.reportingTool =
      "https://reports.adm-kafeteria.staging.aws.mybenefit.pl";
  }
  // // backwards compatibility with dv1.dev etc.
} else if (typeof domainRegex?.groups?.env !== "undefined") {
  if (domainRegex?.groups?.env === "dv1") {
    featureFlags.apiUrl =
      "https://status.mybenefit.g4n.eu/?action=getFeatureFlags&env=dv1";
    window.reportingTool = "https://reports.adm-kafeteria.dev.aws.mybenefit.pl";
  } else if (domainRegex?.groups?.env === "qa1") {
    featureFlags.apiUrl =
      "https://status.mybenefit.g4n.eu/?action=getFeatureFlags&env=qa1";
    window.reportingTool =
      "https://reports.adm-kafeteria.staging.aws.mybenefit.pl";
  }
  // Prod and new urls for dev and staging.
} else if (typeof domainRegex?.groups?.adm !== "undefined") {
  if (domainRegex?.groups?.envAdm === ".dev.aws") {
    featureFlags.apiUrl =
      "https://status.mybenefit.g4n.eu/?action=getFeatureFlags&env=dv1";
    window.reportingTool = "https://reports.adm-kafeteria.dev.aws.mybenefit.pl";
  } else if (domainRegex?.groups?.envAdm === ".staging.aws") {
    featureFlags.apiUrl =
      "https://status.mybenefit.g4n.eu/?action=getFeatureFlags&env=qa1";
    window.reportingTool =
      "https://reports.adm-kafeteria.staging.aws.mybenefit.pl";
  } else if (domainRegex?.groups?.envAdm === "") {
    // for prod featureFlags are left as "", based on terraform values file
    window.reportingTool = "https://reports.adm-kafeteria.mybenefit.pl";
  }
}

const renderApp = (Component) => {
  const version = localStorage.getItem(VERSION_KEY);

  if (!version || version !== getVersion()) {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem(VERSION_KEY, getVersion());
  }

  root.render(
    <InspectorWrapper
      keys={["control", "alt", "z"]}
      disableLaunchEditor={false}
      onHoverElement={(params) => {}}
      onClickElement={(params) => {}}
    >
      <Provider store={store}>
        <FeatureFlagsProvider options={featureFlags}>
          <RbsAppWrapper>
            <BrowserRouter getUserConfirmation={getUserConfirmationPopup}>
              <ScrollToTop />
              <Component />
            </BrowserRouter>
          </RbsAppWrapper>
        </FeatureFlagsProvider>
      </Provider>
    </InspectorWrapper>
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    // eslint-disable-next-line global-require
    const NextApp = require("./Pages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
