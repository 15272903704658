import React from "react";
import { Link } from "react-router-dom";
import AppMobileMenu from "src/Layout/AppMobileMenu";

export default function AppLogo() {
  const { href } = window.location;
  const logoCondition = process.env?.REACT_APP_OMB_MICROSERVICES_URL || href;

  const envNo = logoCondition.match(/qa1-(.*?).omb/i)?.[1];

  return (
    <>
      <div className="app-header__logo">
        <Link to="/">
          <div
            className={
              logoCondition?.includes("qa1") ? "logo-src-stage" : "logo-src"
            }
            data-t1="logo"
            data-t2={envNo}
          />
        </Link>
      </div>
      <AppMobileMenu />
    </>
  );
}
