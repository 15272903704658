const shouldSkipSubscriptionSection = (section) => {
  const isUMS = window.location.pathname.includes("/ums-subscriptions/");
  const isSubscriptions = window.location.pathname.includes("/subscriptions/");

  return (
    (isUMS && section.id !== "subscriptionUMS") ||
    (isSubscriptions && section.id !== "subscription")
  );
};

export default shouldSkipSubscriptionSection;
