export const GROUP_TYPE_SINGLE_CHOICE = "single";
export const GROUP_TYPE_MULTI_CHOICE = "multichoice";

export const declarationsRequiredInEditUmsBenefitGroup = [
  "showRegulationAgreement",
  "regulationAgreement",
  "showPersonalDataAgreement",
  "personalDataAgreement",
  "showPersonalDataAccompanyingPersonAgreement",
  "personalDataAccompanyingPersonAgreement",
  "showDispositionEmailAccompanyingPersonAgreement",
  "dispositionEmailAccompanyingPersonAgreement",
  "showPersonalDataChildAgreement",
  "personalDataChildAgreement",
  "showStatementAccuracyChildData",
  "statementAccuracyChildData",
];

export const declarationsRequiredInEditUmsBenefitSubgroup = [
  "showPersonalDataAgreement",
  "personalDataAgreement",
  "showPersonalDataAccompanyingPersonAgreement",
  "personalDataAccompanyingPersonAgreement",
  "showDispositionEmailAccompanyingPersonAgreement",
  "dispositionEmailAccompanyingPersonAgreement",
  "showPersonalDataChildAgreement",
  "personalDataChildAgreement",
  "showStatementAccuracyChildData",
  "statementAccuracyChildData",
];

export const BENEFIT_GROUP_IRI_PREFIX =
  "api/subscription-management/v1/rest/benefit-groups";
export const DYNAMIC_DECLARATION_PREFIX_UMS =
  "subscriptionUMS/dynamicDeclaration";
export const GENERAL_CONFIG_PREFIX = "subscription/generalConfig";
export const GENERAL_CONFIG_PREFIX_UMS = "subscriptionUMS/generalConfig";

export const defaultGroupFields = [
  {
    id: "manualActivation",
    valueFrom: "company",
    valueType: "boolean",
    prefix: GENERAL_CONFIG_PREFIX,
  },
  {
    id: "manualActivationType",
    valueFrom: "company",
    valueType: "text",
    prefix: GENERAL_CONFIG_PREFIX,
  },
  {
    id: "pendingDay",
    valueFrom: "company",
    valueType: "text",
    prefix: GENERAL_CONFIG_PREFIX,
  },
];

export const defaultUMSGroupFields = [
  {
    id: "startDaySubscriptionCard",
    valueFrom: "company",
    valueType: "number",
    prefix: GENERAL_CONFIG_PREFIX_UMS,
  },
  {
    id: "manualActivation",
    valueFrom: "company",
    valueType: "boolean",
    prefix: GENERAL_CONFIG_PREFIX_UMS,
  },
  {
    id: "manualActivationType",
    valueFrom: "company",
    valueType: "text",
    prefix: GENERAL_CONFIG_PREFIX_UMS,
  },
  {
    id: "pendingDay",
    valueFrom: "company",
    valueType: "text",
    prefix: GENERAL_CONFIG_PREFIX_UMS,
  },
];

export const defaultSubgroupFields = [
  { id: "supplierId", valueFrom: "group", valueType: "asyncAutocomplete" },
  { id: "category", valueFrom: "group", valueType: "autocomplete" },
  { id: "billingType", valueFrom: "group", valueType: "select" },
  {
    id: "pendingDay",
    valueFrom: "company",
    valueType: "text",
    prefix: GENERAL_CONFIG_PREFIX_UMS,
  },
  {
    id: "manualActivation",
    valueFrom: "company",
    valueType: "boolean",
    prefix: GENERAL_CONFIG_PREFIX_UMS,
  },
  { id: "benefitDescription", valueFrom: "group", valueType: "wysiwyg" },
  {
    id: "manualActivationType",
    valueFrom: "company",
    valueType: "text",
    prefix: GENERAL_CONFIG_PREFIX_UMS,
  },
  {
    id: "sportCard",
    valueFrom: "group",
    valueType: "boolean",
  },
  {
    id: "enableReactivationSubscription",
    valueFrom: "group",
    valueType: "boolean",
  },
  {
    id: "modeReactivationSubscription",
    valueFrom: "group",
    valueType: "radio",
  },
];

export const defaultBenefitConfig = {
  subscriptionPriority: 50,
};

export const employeeConfigElements = {
  ouIds: "organizationUnitIds",
  ergIds: "employeeRentableGroupIds",
};

export const GROUPS_PREFIX = "/benefit-groups";
export const SUBGROUPS_PREFIX = "/benefit-subgroups";

export const DEFAULT_FIELD_SUFFIX = "IsDefault";

export const billingTypes = {
  RECHARGING_RECHARGING: "recharging-recharging",
  MONTH_TO_MONTH: "month-to-month",
  BY_THE_ACCOUNTING_YEAR: "by-the-accounting-year",
};
