import pako from "pako";
import crypto from "crypto";
// eslint-disable-next-line import/no-cycle
import { restApiRequest, setIsMockView, SSO_SERVICE } from "src/utils/Api";
import { SELECTED_COMPANY_STORAGE_KEY } from "src/Pages/Report/helpers/companyHelper";
import UserInfo from "./UserInfo";
// eslint-disable-next-line import/no-cycle
import { MOCK_ADMIN_ACCESS_TOKEN } from "./tmpAuthorization";
import { BLOCKED_REQUESTS_STORAGE_KEY } from "./blockedRequests";

export const USER_INFO_STORAGE_KEY = "user_info";
export const TMP_LOGIN_STORAGE_KEY = "tmp_login";
export const SESSION_STORAGE_KEY = "sso-token";
export const SESSION_STORAGE_ORIGIN_KEY = "sso-token-origin";
export const IMPERSONATE_EMPLOYEE_COOKIE_NAME = "kcImp";
export const IMPERSONATE_AHR_COOKIE_NAME = "kcImpA";
export const IMPERSONATE_COOKIE_DOMAIN = "mybenefit.pl";

let singletonHandler = null;

class Session {
  constructor(
    accessToken = null,
    refreshToken = null,
    date = 0,
    expiresIn = 0
  ) {
    this.setData(accessToken, refreshToken, date, expiresIn);
    this.getUserInfo = () => new UserInfo({}); // TO REMOVE
    if (!localStorage.getItem(TMP_LOGIN_STORAGE_KEY)) {
      this.refreshTokenInBackground();
    }
  }

  refreshTokenInBackground() {
    setInterval(() => {
      this.refreshTokenXhr().then((response) => {
        if (
          response &&
          response.date &&
          response.expiresIn &&
          response.idToken &&
          response.refreshToken &&
          response.token &&
          response.tokenType
        ) {
          const jsTimestamp = response.date * 1000;
          localStorage.setItem(
            SESSION_STORAGE_KEY,
            JSON.stringify({
              date: jsTimestamp,
              expires_in: response.expiresIn,
              id_token: response.idToken,
              refresh_token: response.refreshToken,
              token: response.token,
              token_type: response.tokenType,
            })
          );
          this.setData(
            response.token,
            response.refreshToken,
            jsTimestamp,
            response.expiresIn
          );
        }
      });
    }, 250000);
  }

  updateSession(token, refreshToken, date, expiresIn) {
    const newSessionData = {
      token,
      refresh_token: refreshToken,
      date,
      expiresIn,
    };
    localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newSessionData));
    this.setData(token, refreshToken, date, expiresIn);
  }

  setData(accessToken, refreshToken, date, expiresIn) {
    this.getRefreshToken = () => refreshToken;
    this.getAccessTokenTtl = () => date + expiresIn * 1000;
    this.getAccessToken = () => accessToken;
  }

  isAccessTokenValid() {
    return Date.now() < this.getAccessTokenTtl();
  }

  async getValidAccessToken() {
    try {
      const token = this.getAccessToken();

      if (token && this.isAccessTokenValid()) {
        return token;
      }

      if (this.getRefreshToken()) {
        this.refreshToken();
        return localStorage.getItem(TMP_LOGIN_STORAGE_KEY)
          ? this.getAccessToken()
          : new Promise(() => this.refreshToken());
      }
    } catch (e) {
      console.error(e);
    }
    return "";
  }

  login(accessToken, refreshToken, date, expiresIn) {
    this.updateSession(accessToken, refreshToken, date, expiresIn);
    localStorage.setItem(TMP_LOGIN_STORAGE_KEY, "1");
  }

  // eslint-disable-next-line class-methods-use-this
  refreshToken() {
    if (!localStorage.getItem(TMP_LOGIN_STORAGE_KEY)) {
      window.location = `/signin/oauth/client/refresh?refresh_token=${this.getRefreshToken()}&referer_url=${encodeURIComponent(
        `${window.location.pathname}`
      )}`;
    } else {
      this.login(
        MOCK_ADMIN_ACCESS_TOKEN,
        MOCK_ADMIN_ACCESS_TOKEN,
        Date.now(),
        86400
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async refreshTokenXhr() {
    return restApiRequest("", "/signin/oauth/client/refreshXhr", "POST", {
      body: {
        refreshToken: encodeURIComponent(this.getRefreshToken()),
      },
    });
  }
}

export function clearSessionData() {
  setIsMockView(false);
  localStorage.removeItem(TMP_LOGIN_STORAGE_KEY);
  localStorage.removeItem(USER_INFO_STORAGE_KEY);
  sessionStorage.removeItem(USER_INFO_STORAGE_KEY);
  sessionStorage.removeItem(BLOCKED_REQUESTS_STORAGE_KEY);
  sessionStorage.removeItem(SELECTED_COMPANY_STORAGE_KEY);
  localStorage.removeItem(SESSION_STORAGE_KEY);
}

export function clearOriginTokenData() {
  localStorage.removeItem(SESSION_STORAGE_ORIGIN_KEY);
}

/**
 * @returns {Session}
 */
export function getSession() {
  if (!singletonHandler) {
    throw new Error("Session has not been initialized");
  }
  return singletonHandler;
}

// In localStorage we keep user info for tmp logins admin:admin omb:omb etc.
export async function getUserInfo() {
  let result;
  const devLoginUserInfo = localStorage.getItem(USER_INFO_STORAGE_KEY);
  if (devLoginUserInfo) {
    result = JSON.parse(devLoginUserInfo);
  } else {
    const savedUserInfoJson = sessionStorage.getItem(USER_INFO_STORAGE_KEY);
    let savedUserInfo = null;
    if (savedUserInfoJson) {
      savedUserInfo = JSON.parse(savedUserInfoJson);
    }
    const [
      {
        nickname: username,
        given_name: firstName,
        family_name: lastName,
        company_id: companyId,
        MyB_SSO_UID: newId,
        sub: oldId,
        business_email: businessEmail,
        personal_email: personalEmail,
      },
      { permissions, relations, role },
    ] = await Promise.all([userInfoRequest(), permissionsRequest()]);
    const id = newId || oldId;
    const userData = {
      id,
      role,
      firstName,
      lastName,
      username,
      businessEmail,
      personalEmail,
      companyId,
      permissions,
      relations,
    };
    if (savedUserInfo && savedUserInfo.id !== id) {
      throw new Error("User id has changed");
    }
    sessionStorage.setItem(USER_INFO_STORAGE_KEY, JSON.stringify(userData));
    localStorage.removeItem(USER_INFO_STORAGE_KEY);
    result = userData;
  }
  return result;
}

export const userInfoRequest = async () =>
  restApiRequest(
    SSO_SERVICE,
    "/userinfo",
    "POST",
    {
      body: {},
    },
    {
      MyB_SSO_UID: "54d539d4-d178-11ea-87d0-0242ac130003",
      sub: "54d539d4-d178-11ea-87d0-0242ac130003",
      name: "jantestowy",
      email: "jan.testowy@example.com",
      role: "omb",
      company_id: "e1e3fbc1-0c0a-4032-9824-f688d167acc5",
      family_name: "Testowy",
      given_name: "Jan",
      nickname: "jantestowy",
      preferred_username: "jantestowy",
    }
  );
export const permissionsRequest = async () =>
  restApiRequest(
    SSO_SERVICE,
    "/me/permissions",
    "GET",
    {},
    {
      role: "omb",
      permissions: [
        "employee:employeeGroup:read",
        "employee:employeeLeave:read",
        "sso:ipAddressRestriction:write",
        "company:attachment:write",
        "company:application:write",
        "employee:ahrRole:read",
      ],
      relations: {
        company: [],
        employeeGroup: [],
        organizationUnit: [],
        rentableGroup: [],
        administeredCompanies: [],
        administeredEmployeeGroups: [],
        administeredOrganizationUnits: [],
      },
    }
  );

export function deleteSession() {
  singletonHandler = null;
}

export const cookieExist = (name) =>
  document.cookie.split(";").some((item) => item.trim().startsWith(name));

const decodeBase64 = (base64String) => {
  const binaryString = window.atob(base64String);
  const byteArray = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
  return byteArray;
};

export const getCookieValue = (name) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(name))
    .split("=")[1];

  const decodedBase64 = decodeURIComponent(cookieValue);
  const byteArray = decodeBase64(decodedBase64);

  const inflated = pako.inflateRaw(byteArray, { to: "string" });
  return inflated;
};

const generateRandomHex = (size) => {
  const array = new Uint8Array(size);
  window.crypto.getRandomValues(array);
  return Array.from(array)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
};

const deflateAndEncode = (value) => {
  const deflated = pako.deflate(value);
  const binaryString = Array.from(deflated)
    .map((byte) => String.fromCharCode(byte))
    .join("");
  const base64 = btoa(binaryString);
  return encodeURIComponent(base64);
};

const setCookie = (name, value, expires, path, domain, sameSite, secure) => {
  const deflatedValue = deflateAndEncode(value);
  let cookie = `${name}=${deflatedValue}; expires=${expires}`;
  if (path) {
    cookie = `${cookie}; path=${path}`;
  }
  if (domain) {
    cookie = `${cookie}; domain=${domain}`;
  }
  if (sameSite) {
    cookie = `${cookie}; SameSite=${sameSite}`;
  }
  if (secure) {
    cookie = `${cookie}; Secure`;
  }
  document.cookie = cookie;
};

const deleteCookie = (name, path, domain, sameSite, secure) => {
  setCookie(name, "", new Date(1), path, domain, sameSite, secure);
};

export function reimpersonate() {
  const storedOrigValue = localStorage.getItem(SESSION_STORAGE_ORIGIN_KEY);

  if (!storedOrigValue) {
    return;
  }

  localStorage.setItem(SESSION_STORAGE_KEY, storedOrigValue);
  localStorage.removeItem(SESSION_STORAGE_ORIGIN_KEY);

  const storedValue = localStorage.getItem(SESSION_STORAGE_KEY);
  const parsedStoredValue = JSON.parse(storedValue);

  let cookieValue = {
    uu: parsedStoredValue.uu,
    acc: parsedStoredValue.token,
    aci: parsedStoredValue.id_token,
    rfr: parsedStoredValue.refresh_token,
  };
  cookieValue = JSON.stringify(cookieValue);

  const expires = new Date();
  expires.setMinutes(expires.getMinutes() + 1);

  deleteCookie(
    IMPERSONATE_AHR_COOKIE_NAME,
    "/",
    IMPERSONATE_COOKIE_DOMAIN,
    "Lax",
    true
  );
  setCookie(
    IMPERSONATE_EMPLOYEE_COOKIE_NAME,
    cookieValue,
    expires,
    "/",
    IMPERSONATE_COOKIE_DOMAIN,
    "Lax",
    true
  );

  deleteSession();
}

/**
 * @returns {Session}
 */
export async function initSession() {
  let session;

  if (!singletonHandler) {
    try {
      let storedValue = localStorage.getItem(SESSION_STORAGE_KEY);
      const storedValueHash = JSON.parse(storedValue)?.hash;
      let storedOrigValue = localStorage.getItem(SESSION_STORAGE_ORIGIN_KEY);
      const storedOrigValueHash = JSON.parse(storedOrigValue)?.hash;

      if (
        storedValueHash !== storedOrigValueHash ||
        (storedOrigValue && !storedOrigValueHash)
      ) {
        // something goes wrong with switching to AHR, delete origin token
        localStorage.removeItem(SESSION_STORAGE_ORIGIN_KEY);
        storedOrigValue = false;
      }

      let impersonated = false;
      let cookieValue = {
        uu: "",
        acc: "",
        aci: "",
        rfr: "",
      };

      if (
        cookieExist(IMPERSONATE_AHR_COOKIE_NAME) &&
        getCookieValue(IMPERSONATE_AHR_COOKIE_NAME)
      ) {
        cookieValue = getCookieValue(IMPERSONATE_AHR_COOKIE_NAME);
        cookieValue = JSON.parse(cookieValue);
        impersonated = true;
      }

      if (!!storedValue && impersonated && !storedOrigValue) {
        // switch values
        const hash = generateRandomHex(20);
        const parsedValue = JSON.parse(storedValue);
        parsedValue.hash = hash;
        localStorage.setItem(
          SESSION_STORAGE_ORIGIN_KEY,
          JSON.stringify(parsedValue)
        );
        const impersonatedValue = {
          token: cookieValue.acc,
          id_token: cookieValue.aci,
          refresh_token: cookieValue.rfr,
          uu: cookieValue.uu,
          token_type: "Bearer",
          expires_in: 900,
          date: Date.now(),
          hash,
        };
        localStorage.setItem(
          SESSION_STORAGE_KEY,
          JSON.stringify(impersonatedValue)
        );
      }

      storedValue = localStorage.getItem(SESSION_STORAGE_KEY);
      let accessToken;
      let refreshToken;
      let date;
      let expiresIn;
      if (storedValue) {
        const parsedValue = JSON.parse(storedValue);
        accessToken = parsedValue.token;
        refreshToken = parsedValue.refresh_token;
        date = parsedValue.date;
        expiresIn = parsedValue.expires_in;
      }
      session = new Session(accessToken, refreshToken, date, expiresIn);
      singletonHandler = session;
    } catch (e) {
      console.error(e);
      session = new Session();
      singletonHandler = session;
    }
  }
  return singletonHandler;
}
