import PropTypes from "prop-types";
import React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createRoot } from "react-dom";
import PopupComponent from "src/Components/Popup/popup";
import __ from "src/utils/Translations";

export default function ChangeEmployeeGroupPopup({ message, onClose }) {
  return (
    <>
      <PopupComponent
        id="oneTimeServiceEditInfo"
        isOpen
        toggle={onClose}
        unmountOnClose
        size="lg"
      >
        <ModalHeader toggle={onClose}>
          {__("Nie można zmienić grupy pracowniczej")}
        </ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onClose}>
            {__("Zamknij")}
          </Button>
        </ModalFooter>
      </PopupComponent>
    </>
  );
}
export function getChangeEmployeeGroupPopup() {
  const message = (
    <>
      {`${__(
        "W docelowej Grupie pracowniczej, nie ma Grupy dochodowości do której należy pracownik"
      )},`}
      {__("jeżeli chcesz dokonać takiej zmiany przypisz pracownika do GD ")}
      {`${__("odpowiedniej dla docelowej Grupy pracowniczej")},`}
      {`${__(
        "lub wyczyść obecną grupę dochodowości i uruchom ponowny wybór pracownikowi"
      )}.`}
    </>
  );
  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  document.body.appendChild(container);
  const root = createRoot(container);
  const closeModal = () => {
    root.unmount();
  };
  root.render(
    <ChangeEmployeeGroupPopup message={message} onClose={closeModal} />
  );
}

ChangeEmployeeGroupPopup.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
};
