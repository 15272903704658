import React from "react";
import {
  GENERAL_CONFIG_PREFIX,
  GENERAL_CONFIG_PREFIX_UMS,
} from "src/Pages/Company/Products/Subscriptions/EditBenefitGroup/constants";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";

const handlePaymentStartDateChange = ({ key, value, data, setData }) => {
  if (
    key.includes("paymentStartDate") &&
    value &&
    (!!data[`${GENERAL_CONFIG_PREFIX}/paymentStartDate`] ||
      !!data[`${GENERAL_CONFIG_PREFIX_UMS}/paymentStartDate`])
  ) {
    getUserConfirmationPopup(
      <>
        <p>
          Uwaga! Dokonujesz zmiany daty pobierania płatności za świadczenia
          abonamentowe. Zmiana ta może pociągnąć za sobą zmiany w wygenerowanych
          już paymentach, a tym samym w raportach płacowych.
        </p>
        <p>
          Czy jesteś pewien, że chcesz zapisać wprowadzone zmiany? Jeśli tak,
          pamiętaj żeby zapisać cały formularz konfiguracji świadczeń.
        </p>
      </>,
      (confirm) => {
        if (confirm) {
          const updatedData = { ...data };
          updatedData[key] = value;
          setData(updatedData);
        }
      },
      "Zmiana daty pobierania płatności za świadczenia abonamentowe"
    );
    return true;
  }

  return false;
};

export default handlePaymentStartDateChange;
