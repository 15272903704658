const shouldSkipField = (field) => {
  // FEN-23373 - to discuss if it should only skip field "paymentStartDate" on configuration
  // or if it should work for all required fields without defaultValue (if there'll be more)
  if (
    window.location.pathname.includes("configuration") &&
    field.id === "paymentStartDate"
    // field.disabledInDefault &&
    // !field.defaultValue
  ) {
    return true;
  }
  return false;
};

export default shouldSkipField;
