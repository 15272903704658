import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import validate from "src/utils/Validation";
// eslint-disable-next-line import/no-cycle
import {
  prepareValidation,
  shouldSkipField,
  shouldSkipSubscriptionSection,
} from "src/Pages/Administration/Configuration/utils";

const omitValidationDependencyReason = (depends, formData) => {
  if (!depends) return false;

  return depends.value !== formData[depends.field];
};

const useValidation = (filteredSchema, formData) => {
  const [invalidFields, setInvalidFields] = useState({});
  const history = useHistory();

  const validateField = useCallback(
    (id, value, validation) => {
      if (!validation) {
        return;
      }
      const message = validate(value, validation);
      if (message === invalidFields[id] || (!message && !invalidFields[id])) {
        return;
      }
      const updatedObject = { ...invalidFields };
      if (message) {
        updatedObject[id] = message;
      } else {
        delete updatedObject[id];
      }
      setInvalidFields(updatedObject);
    },
    [setInvalidFields, invalidFields]
  );

  const validateForm = () => {
    const validationResult = {};
    const formElementsToValidate = [];
    filteredSchema.forEach((section) => {
      if (shouldSkipSubscriptionSection(section)) {
        return;
      }
      if (section.children) {
        section.children.forEach((group) => {
          if (group.children) {
            group.children.forEach((field) => {
              if (shouldSkipField(field)) {
                return;
              }
              formElementsToValidate.push({
                id: `${section.id}/${group.id}/${field.id}`,
                validation: prepareValidation(
                  field.validation,
                  formData,
                  field.id
                ),
                type: field.type || "",
                depends: field.depends,
              });
            });
          }
        });
      }
    });
    formElementsToValidate.forEach(({ id, validation, depends }) => {
      const hasValue = formData[id] !== null && formData[id] !== undefined;
      if (
        hasValue &&
        validation &&
        !omitValidationDependencyReason(depends, formData)
      ) {
        const valueToCheck = formData[id];
        const message = validate(valueToCheck, validation);
        if (message) {
          validationResult[id] = message;
        }
      }
    });
    setInvalidFields(validationResult);
    if (Object.keys(validationResult).length) {
      history.push(`#${Object.keys(validationResult)[0].split("/")[0]}`);
    }
    return Object.keys(validationResult).length === 0;
  };

  return { invalidFields, validateForm, validateField };
};

export default useValidation;
