export const defaultValidation = { method: "maxLength", args: [255] };

export const getActualValidation = (validation, type) => {
  switch (type) {
    case "number":
    case "text":
    case "time":
      if (validation?.some((el) => el.method === defaultValidation.method)) {
        // if 'maxLength' already exists, return the validation array as is so it doesn't overwrite
        return validation;
      }
      return validation
        ? [...validation, defaultValidation]
        : [defaultValidation];
    default:
      return validation;
  }
};
