/* eslint-disable class-methods-use-this */
/* eslint-disable no-dupe-class-members */
import React, { createContext, useContext } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import * as signalR from "@microsoft/signalr";
import { getServiceHost, REPORT_SERVICE } from "src/utils/Api";
import { getSession } from "src/utils/RoleBasedSecurity/Session";
import { dynamicNotification } from "src/utils/Notifications";
import { getAhrUrl } from "src/Pages/Report/helpers/ahrHelper";
import { Button } from "reactstrap";
import __ from "src/utils/Translations";
import { Link } from "react-router-dom";

class SignalRService {
  constructor() {
    this.connection = null;
    this.onMessageExternalEvent = null;
  }

  async prepare() {
    const session = getSession();
    const path = "/ws/reports/hub";

    /**
     * Check if any of:
     *
     * https://adm-kafeteria.dev.aws.mybenefit.pl
     * https://dv1.dev.aws.mybenefit.pl/
     * https://dv1-<number>.omb.mybenefit.g4n.eu
     *
     * https://adm-kafeteria.staging.aws.mybenefit.pl
     * https://qa1.staging.aws.mybenefit.pl
     * https://qa1-<number>.omb.mybenefit.g4n.eu
     *
     * https://adm-kafeteria.mybenefit.pl
     */

    let baseUrl = "";
    // dv1
    if (window.location.href.indexOf("dev.aws.mybenefit.pl") > -1) {
      baseUrl = "https://adm-kafeteria.dev.aws.mybenefit.pl";
    }
    // dv1
    else if (
      window.location.href.indexOf("dv1-") > -1 &&
      window.location.href.indexOf("omb.mybenefit.g4n.eu") > -1
    ) {
      baseUrl = "https://adm-kafeteria.dev.aws.mybenefit.pl";
    }
    // qa1
    else if (window.location.href.indexOf("staging.aws.mybenefit.pl") > -1) {
      baseUrl = "https://adm-kafeteria.staging.aws.mybenefit.pl";
    }
    // qa1
    else if (
      window.location.href.indexOf("qa1-") > -1 &&
      window.location.href.indexOf("omb.mybenefit.g4n.eu") > -1
    ) {
      baseUrl = "https://adm-kafeteria.staging.aws.mybenefit.pl";
    }
    // prod
    else if (window.location.href.indexOf("adm-kafeteria.mybenefit.pl") > -1) {
      baseUrl = "https://adm-kafeteria.mybenefit.pl";
    }
    // develop
    else {
      baseUrl = "http://localhost:8080";
    }
    const url = `${baseUrl}${path}`;

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: async () =>
          `Bearer ${await session.getValidAccessToken()}`,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    return Promise.resolve();
  }

  start() {
    return this.connection.start();
  }

  on(event, callback) {
    this.connection.on(event, callback);
  }

  off(event, callback) {
    this.connection.off(event, callback);
  }

  send(event, data) {
    this.connection.invoke(event, data).catch((err) => console.error(err));
  }

  attachEvents(isAhr) {
    this.on("connected", this.handleConnectedMessage);
    this.on("updatestatus", this.handleUpdateStatusMessage);
    if (isAhr) {
      this.on("reportgenerated", this.handleReportGeneratedAhrMessage);
    } else {
      this.on("reportgenerated", this.handleReportGeneratedMessage);
    }
    this.on("shutdown", this.stopConnection);
  }

  detachEvents(isAhr) {
    this.off("connected", this.handleConnectedMessage);
    this.off("updatestatus", this.handleUpdateStatusMessage);
    if (isAhr) {
      this.off("reportgenerated", this.handleReportGeneratedAhrMessage);
    } else {
      this.off("reportgenerated", this.handleReportGeneratedMessage);
    }
    this.off("shutdown", this.stopConnection);
  }

  stopConnection() {
    this.connection.stop().then(() => {});
  }

  handleConnectedMessage = (msg) => {};

  handleUpdateStatusMessage = (msg) => {
    const parsedMessage = JSON.parse(msg);
    if (this.onMessageExternalEvent != null) {
      this.onMessageExternalEvent(parsedMessage.Uid, parsedMessage.Status);
    }
    if (parsedMessage.Status === 99) {
      this.handleGenerateErrorMessage(parsedMessage);
    }
  };

  handleReportGeneratedMessage = (msg) => {
    this.handleReportGeneratedMessage(msg, false);
  };

  handleReportGeneratedAhrMessage = (msg) => {
    this.handleReportGeneratedMessage(msg, true);
  };

  handleReportGeneratedMessage = (msg, isAhr) => {
    const parsedMessage = JSON.parse(msg);
    const server = getServiceHost(REPORT_SERVICE);
    const url = `${
      window.reportingTool
    }${server}${`/archive/report/file?archivedReportId=${parsedMessage.Uid}`}`;

    const downloadReportFile = async (url, archiveDetails) => {
      fetch(url, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getSession().getValidAccessToken()}`,
        },
        // 1. Convert the data into 'blob'
      })
        .then((response) => response.blob())
        .then((blob) => {
          if (blob.size !== 0) {
            // 2. Create blob link to download
            const urlfile = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = urlfile;
            link.setAttribute("download", `${__(archiveDetails.FileName)}`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
          }
        });
    };

    const QueueToastMessage = () => (
      <div>
        <div
          style={{
            display: "block",
            height: 30,
            width: 30,
            background: "#8ebf8e",
            right: 0,
            top: 0,
            position: "absolute",
            borderBottomLeftRadius: 12,
          }}
        />
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.2077 6.94825L9.16683 12.9891L5.876 9.70742L4.5835 10.9999L9.16683 15.5833L16.5002 8.24992L15.2077 6.94825ZM11.0002 1.83325C5.94016 1.83325 1.8335 5.93992 1.8335 10.9999C1.8335 16.0599 5.94016 20.1666 11.0002 20.1666C16.0602 20.1666 20.1668 16.0599 20.1668 10.9999C20.1668 5.93992 16.0602 1.83325 11.0002 1.83325ZM11.0002 18.3333C6.9485 18.3333 3.66683 15.0516 3.66683 10.9999C3.66683 6.94825 6.9485 3.66659 11.0002 3.66659C15.0518 3.66659 18.3335 6.94825 18.3335 10.9999C18.3335 15.0516 15.0518 18.3333 11.0002 18.3333Z"
            fill="#2E7D32"
          />
        </svg>
        <strong
          style={{
            color: "#1E4620",
            fontWeight: 700,
            fontSize: "16px",
            marginLeft: "10px",
          }}
        >
          {__("Raport wygenerowany!")}
        </strong>
        <br />
        <div
          style={{
            color: "#1E4620",
            fontWeight: 400,
            marginTop: "10px",
            paddingLeft: "30px",
          }}
        >
          {parsedMessage.Name}
        </div>{" "}
        <br />
        <Button
          role="button"
          color="link"
          style={{
            color: "#2E7D32",
            textTransform: "uppercase",
            fontWeight: 600,
            padding: 0,
            paddingLeft: "20px",
            paddingBottom: "10px",
          }}
          onClick={() => downloadReportFile(url, parsedMessage)}
        >
          {" "}
          {__("Pobierz raport")}
        </Button>
        <br />
        <Link to={getAhrUrl(`/report/archive`, isAhr)}>
          <Button
            role="button"
            color="link"
            style={{
              color: "#2E7D32",
              textTransform: "uppercase",
              fontWeight: 600,
              padding: 0,
              paddingLeft: "20px",
            }}
          >
            {" "}
            {__("Przejdź do Twoje raporty")}
          </Button>
        </Link>
      </div>
    );

    dynamicNotification(QueueToastMessage, "success", "top-right", 10000);
  };

  handleGenerateErrorMessage = (parsedMessage) => {
    const QueueToastMessage = () => (
      <div>
        <strong>{__("Wystąpił błąd podczas generowania raportu:")}</strong>
        <br />
        {parsedMessage.Name}
      </div>
    );
    dynamicNotification(QueueToastMessage, "error", "top-right", 10000);
  };
}
const signalRService = new SignalRService();

export default signalRService;
