import React from "react";
import { isEmpty } from "lodash";

const ASSETS_MANIFEST_FILENAME = "assets-manifest.json";

const ComponentsUrl = () => {
  const { origin } = window.location;
  const componentsUrl = process.env?.OMB_MICROSERVICES_URL || origin;

  return `${componentsUrl}/components`;
};

const fetchAssetsManifest = async (appName) =>
  fetch(`${ComponentsUrl()}/${appName}/${ASSETS_MANIFEST_FILENAME}`, {
    cache: "no-cache",
  }).then((response) => response.json());

const getEntrypointFiles = (manifest) => {
  const { css = [], js = [] } = manifest.entrypoints.main.assets;

  return {
    css,
    js,
  };
};

export const generateCssLinks = (appName, css = []) => {
  if (isEmpty(css)) {
    return [];
  }

  return css.map((cssFile) => (
    <link
      key={cssFile}
      href={`${ComponentsUrl()}/${appName}/${cssFile}`}
      rel="stylesheet"
    />
  ));
};

export const generateJsScripts = (appName, js = []) => {
  if (isEmpty(js)) {
    return [];
  }

  return js.map((jsFile) => (
    <script
      key={jsFile}
      src={`${ComponentsUrl()}/${appName}/${jsFile}`}
      type="text/javascript"
    />
  ));
};

export const loadAppData = async (appName) => {
  const manifest = await fetchAssetsManifest(appName);
  const entrypoints = getEntrypointFiles(manifest);

  return { appName, entrypoints };
};

export default loadAppData;
