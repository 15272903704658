/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import { Loader } from "react-loaders";
import _, { isEmpty } from "lodash";
import Form from "src/Components/Form";
import DataLoading from "src/Components/Loading/dataLoading";
import useOrganizationUnits from "src/utils/hooks/company/useOrganizationUnits";
import useRentableGroups from "src/utils/hooks/company/useRentableGroups";
import useEmployeeGroups, {
  IRI_PREFIX,
} from "src/utils/hooks/company/useEmployeeGroups";
import { getIdFromIri } from "src/utils/jsHelpers/iriConverter";
import { DICTIONARY_PROVINCES } from "src/utils/hooks/dictionaries/dictionariesCodes";
import { LAYOUT_ONE_COLUMN, LAYOUT_TWO_COLUMNS } from "src/Components/Layouts";
import {
  EMPLOYEE_MANAGEMENT_SERVICE,
  SUBSCRIPTION_MANAGEMENT_SERVICE,
  restApiRequest,
} from "src/utils/Api";
import { dynamicNotification } from "src/utils/Notifications";
import __ from "src/utils/Translations";
import {
  useCompanyHasFunctionality,
  useCompanyOverriddenLabels,
} from "src/Pages/Company/CompanyContext";
import DefaultTooltip from "src/Components/Tooltips/DefaultTooltip";
import generatePassword from "src/utils/jsHelpers/generatePassword";
import getUserConfirmationPopup from "src/utils/system/DOM/getUserConfirmationPopup";
import {
  employeeDepartmentPermissionWrite,
  employeeEmployeePermissionWrite,
  employeeRentableGroupSelectionWindowPermissionWrite,
  ombTwoFactorLoginReset,
  ssoCanSwitchUser,
} from "src/utils/RoleBasedSecurity/permissions";
import { clearSessionData } from "src/utils/RoleBasedSecurity/Session";
import RbsContext from "src/utils/RoleBasedSecurity/RbsContext";
import { defaultLanguage } from "src/utils/Translations/translationUtils";
import {
  ACTIVATION_MODE_RANGE,
  getWindowPrefix,
  individualActivationModeOptions,
  individualActivationModeOptionPopup,
  WINDOW_TYPE_ACCOUNT,
  WINDOW_TYPE_POPUP,
  windowTypeOptions,
} from "src/Pages/Company/EmployeeManagement/RentableGroupsWindow/utils/consts";
import { STATUS_CANCELED } from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/Subscriptions/utils";
import useHasPermission from "src/utils/hooks/security/useHasPermission";
import useDictionariesMap from "src/utils/hooks/dictionaries/useDictionariesMap";
import useConfig from "src/utils/hooks/configuration/useConfig";
import { isFutureDate } from "src/Pages/Company/EmployeeManagement/RentableGroupsWindow/utils/utils";
import {
  fetchRentableGroupsByEmployeeGroupIri,
  getIdFromIriRentableGroup,
} from "src/utils/Company";
import useDepartments from "src/utils/hooks/company/useDepartments";
import { getEmployeesOptionsFetchMethod } from "src/Components/FormElements/Autocomplete/commonFetchMethods";
import uuid from "src/utils/jsHelpers/uuid";
import hasAtLeastOneGroupAutoReplaceBenefitAfterChangeGP from "src/Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/utils/hasAtLeastOneGroupAutoReplaceBenefitAfterChangeGP";
import {
  getChangeEmployeeGroupPopupMessage,
  getChangeEmployeeGroupPopupTitle,
} from "src/utils/constants/getChangeEmployeeGroupPopupValue";
import newRelicErrorReport from "src/utils/newRelic/newRelicErrorReport";
import {
  getWarsawDate,
  getLocalDate,
} from "src/utils/jsHelpers/getDateAndTime";
import translateLabels from "src/utils/Translations/translateLabels";
import { messageTypes } from "src/Components/InfoModal/consts";
import { getInfoModal } from "src/utils/system/modal";
import { getChangeEmployeeGroupPopup } from "./changeEmployeeGroupPopup";
import {
  deleteOMBOnlyFields,
  fetchRentableGroupSelectionWindow,
  isActivationModeInfinity,
  saveRentableGroupSelectionWindow,
  checkOpenSubscriptions,
  downloadFile,
} from "./utils";
import shouldDisplayNameChangeModal from "./utils/shouldDisplayNameChangeModal";

const dictionariesCodes = [DICTIONARY_PROVINCES];

const fieldsWithoutEditValidation = ["emailType"];

const getEmailTooltip = (originalData, isAhr, config) => {
  if (!originalData.businessEmail) {
    return "Aby wysłać e-mail pracownik musi mieć zapisany służbowy e-mail.";
  }
  if (
    !config["email-templates/email-welcome/email-subject"] ||
    !config["email-templates/email-welcome/template"]
  ) {
    if (isAhr) {
      return "Wysyłanie e-maili niedostępne, skontaktuj się z administratorem.";
    }
    return "Aby wysłać e-mail pracownik musi mieć skonfigurowany szablon.";
  }
  return null;
};

const emailTypesOptions = [
  { value: "personalEmail", label: "prywatny" },
  { value: "businessEmail", label: "służbowy" },
];

export default function General({
  active,
  setIsEdited,
  isNew,
  companyId,
  employeeId,
  setEmployeeData,
  employeeData,
}) {
  const [data, updateData] = useState(
    isNew ? { preferredLanguage: defaultLanguage } : null
  );
  const [originalData, updateOriginalData] = useState({});
  const [windowSelectionData, setWindowSelectionData] = useState([]);
  const [individualWindowsEnabled, setIndividualWindowsEnabled] =
    useState(false);
  const [loadingWindowSelection, setLoadingWindowSelection] = useState(false);
  const [loadingEmployeeGroup, setLoadingEmployeeGroup] = useState(false);
  const [isEditableIndividualWindow, setIsEditableIndividualWindow] =
    useState(true);
  const [blockedButtons, setBlockedButtons] = useState(false);
  const [blockedActivation, setBlockedActivation] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [subData, setSubData] = useState([]);
  const history = useHistory();
  const rbsContext = useContext(RbsContext);
  const { workPlace, costPlace, organizationUnit } =
    useCompanyOverriddenLabels();
  const isAhr = rbsContext.userInfo.isAhr();
  const employeeGroupId = data?.employeeGroup
    ? getIdFromIri(data.employeeGroup, IRI_PREFIX)
    : null;
  const config = useConfig(companyId, employeeGroupId);
  const [isApplicable, setIsApplicable] = useState(false);
  const [formerIdp, setFormerIdp] = useState(false);

  useEffect(() => {
    updateData(null);
  }, [employeeId]);

  useEffect(() => {
    if (!employeeData) return;
    setWarsawTime(employeeData);
  }, [JSON.stringify(employeeData)]);

  useEffect(() => {
    updateData((data) => ({ ...data, companyId }));
  }, [companyId]);

  useEffect(() => {
    fetchIdentityProvider();
  }, [companyId]);

  const setWarsawTime = (employeeData) => {
    const [warsawDateTo, warsawTimeTo] = getWarsawDate(employeeData?.activeTo);
    const [warsawDateFrom] = getWarsawDate(employeeData?.activeFrom);

    updateData((data) => ({
      ...data,
      ...employeeData,
      ...(warsawDateTo ? { localActiveTo: warsawDateTo } : {}),
      ...(warsawTimeTo ? { localActiveToTime: warsawTimeTo } : {}),
      ...(warsawDateFrom ? { localActiveFrom: warsawDateFrom } : {}),
    }));
  };

  const switchIdentityProvider = () => {
    if (!formerIdp) {
      if (!isEmpty(originalData) && originalData.personalEmail === undefined) {
        dynamicNotification(
          __(
            "Pracownik nie ma zdefiniowanego prywatnego adresu e-mail i może to spowodować, że nie będzie mógł się zalogować do systemu."
          ),
          "error",
          "",
          5000
        );
      }
    }
    putIdentityProvider();
  };

  const fetchIdentityProvider = useCallback(async () => {
    try {
      const result = await restApiRequest(
        "",
        `/auth/realms/cafeteria_system/mybenefit-api/external/v1/users/${employeeId}/formerIdp`,
        "GET",
        {},
        []
      );
      setIsApplicable(result.isApplicable);
      setFormerIdp(result.formerIdp);
    } catch (errorData) {
      console.error(errorData);
      newRelicErrorReport(
        errorData,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 157"
      );
    }
  }, [employeeId]);

  const putIdentityProvider = useCallback(async () => {
    try {
      const body = {
        formerIdp: !formerIdp,
      };
      const result = await restApiRequest(
        "",
        `/auth/realms/cafeteria_system/mybenefit-api/external/v1/users/${employeeId}/formerIdp`,
        "PUT",
        {
          body,
        },
        {},
        []
      );
      setFormerIdp(!formerIdp);
    } catch (errorData) {
      console.error(errorData);
      newRelicErrorReport(
        errorData,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 157"
      );
    }
  }, [formerIdp, restApiRequest]);

  const fetchSubscriptionData = useCallback(async () => {
    try {
      let filteredSubData = [];
      const result = await restApiRequest(
        SUBSCRIPTION_MANAGEMENT_SERVICE,
        `/employees/${employeeId}/subscriptions`,
        "GET",
        {},
        []
      );

      if (result?.length) {
        filteredSubData = result.flatMap((group) =>
          group.items.filter((ele) => ele.status !== STATUS_CANCELED)
        );
      }

      setSubData(filteredSubData);
    } catch (errorData) {
      console.error(errorData);
      newRelicErrorReport(
        errorData,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 157"
      );
    }
  }, [employeeId]);

  useEffect(() => {
    if (!isNew && active) {
      fetchSubscriptionData();
    }
  }, [active, isNew, fetchSubscriptionData]);

  const onChange = useCallback(
    (key, value) => {
      updateData((previousData) => {
        const updatedData = { ...previousData };
        updatedData[key] = value === "" ? null : value;
        if (!fieldsWithoutEditValidation.includes(key)) {
          setIsEdited(true);
        }
        return updatedData;
      });
    },
    [setIsEdited]
  );

  const onChangeActiveFrom = (id, date) => {
    const activeFrom = getLocalDate(date);
    onChange(id, date);
    onChange("activeFrom", activeFrom);
    onChange("activeFromDate", activeFrom);
  };

  const onChangeDeactive = (id, date) => {
    let newDate = date;
    if (newDate) {
      newDate = getLocalDate(date);
    }
    onChange("activeToPrev", data[id]);
    onChange(id, date);
    onChange("activeTo", newDate);
    onChange("activeToDate", newDate);
  };

  const onChangeWindow = (key, value, type) => {
    const updatedWindowsSelectionData = [...windowSelectionData];
    let filedValue = {};
    if (key === "rangeDate") {
      const { from, to } = value;
      filedValue = {
        [`${key}From`]: from,
        [`${key}FromLocal`]: getLocalDate(from, true),
        [`${key}To`]: to,
        [`${key}ToLocal`]: getLocalDate(to),
      };
    } else {
      filedValue = { [key]: value };
    }
    const window = updatedWindowsSelectionData.find(
      ({ windowType }) => windowType === type
    );
    if (window) {
      updatedWindowsSelectionData[updatedWindowsSelectionData.indexOf(window)] =
        { ...window, ...filedValue };
    } else {
      updatedWindowsSelectionData.push({
        windowType: type,
        companyId,
        ...filedValue,
        enabled: true,
      });
    }
    setIsEdited(true);
    setWindowSelectionData(updatedWindowsSelectionData);
  };

  const enableWindow = (windowsTypes) => {
    const newWindowSelectionData = [];
    [WINDOW_TYPE_ACCOUNT, WINDOW_TYPE_POPUP].forEach((type) => {
      let window = windowSelectionData.find(
        ({ windowType }) => windowType === type
      );
      if (windowsTypes.includes(type)) {
        if (window) {
          window.enabled = true;
        } else {
          window = { windowType: type, enabled: true, companyId };
        }
      } else if (window) {
        window.enabled = false;
      }
      if (window) {
        newWindowSelectionData.push({ ...window });
      }
    });
    setIsEdited(true);
    setWindowSelectionData(newWindowSelectionData);
  };

  const dictionaries = useDictionariesMap(dictionariesCodes);
  const organizationUnits = useOrganizationUnits(
    true,
    "companyId",
    companyId,
    true,
    !active || !companyId
  );
  const employeeGroups = useEmployeeGroups(
    true,
    "companyId",
    companyId,
    true,
    !active || !companyId
  );
  const rentableGroups = useRentableGroups(
    true,
    "employeeGroups",
    data?.employeeGroup,
    true,
    !data?.employeeGroup
  );
  const departments = useDepartments(
    true,
    "companyId",
    companyId,
    true,
    !active || !companyId
  );

  const peselFunctionality = useCompanyHasFunctionality("ENABLE_PESEL");
  const mpFunctionality = useCompanyHasFunctionality("ENABLE_WORK_PLACE");
  const mpkFunctionality = useCompanyHasFunctionality("ENABLE_COST_PLACE");
  const enableOrganizationUnits = useCompanyHasFunctionality(
    "ENABLE_OBLIGATORY_ORGANIZATION_UNITS"
  );
  const departmentFunctionality = useCompanyHasFunctionality(
    "ENABLE_COMPANY_STRUCTURE"
  );
  const enableConfigurationRentableAndOrganization = useCompanyHasFunctionality(
    "ENABLE_CONFIGURATION_BENEFITS_FOR_RENTABLE_GROUPS_AND_ORGANIZATION_UNITS"
  );

  const displayPopupToAhrAfterChangeEmployeeOrganizationUnit =
    useCompanyHasFunctionality(
      "DISPLAY_POPUP_TO_AHR_AFTER_CHANGING_EMPLOYEE_OU"
    );
  const notificationAfterChangeEmployeeNamesForOrderDuplicateMs =
    useCompanyHasFunctionality(
      "ENABLE_AHR_NOTIFICATION_AFTER_CHANGING_EMPLOYEE_NAMES_FOR_ORDER_DUPLICATE_MS"
    );
  const disableAdminEmployeeUserNameChange = useCompanyHasFunctionality(
    "DISABLE_ADMIN_EMPLOYEE_USER_NAME_CHANGE"
  );
  const hasWritePermission = useHasPermission(employeeEmployeePermissionWrite);
  const hasAccessToSelectionWindowsWrite =
    useHasPermission(employeeRentableGroupSelectionWindowPermissionWrite) ||
    (isAhr && hasWritePermission);
  const hasDepartmentWritePermission = useHasPermission(
    employeeDepartmentPermissionWrite
  );
  const hasOmbTwoFactorLoginResetPermission = useHasPermission(
    ombTwoFactorLoginReset
  );
  const hasEmail = originalData.businessEmail || originalData.personalEmail;
  const generateAndSendPassword = useCallback(async () => {
    try {
      const emailType = data?.emailType;
      const password = hasEmail
        ? await restApiRequest(
            EMPLOYEE_MANAGEMENT_SERVICE,
            `/employee/${employeeId}/generate-and-send-password`,
            "PATCH",
            {
              body: {
                ...(emailType ? { emailType } : {}),
              },
            },
            null
          )
        : generatePassword();
      dynamicNotification(
        __(
          hasEmail
            ? "Nowe hasło zostało wygenerowane i wysłane na adres e-mail użytkownika"
            : "Zostało wygenerowane hasło"
        )
      );
      if (!hasEmail && typeof password === "string") {
        const updatedData = { ...data };
        updatedData.password = password;
        updatedData.password2 = password;
        updateData(updatedData);
        setGeneratedPassword(password);
      }
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 587"
      );
      dynamicNotification(
        e.message || __("Nie udało się wygenerować hasła"),
        "error"
      );
    }
  }, [data, hasEmail]);

  const resetPassword = useCallback(async () => {
    try {
      await restApiRequest(
        "",
        `/auth/realms/cafeteria_system/mybenefit-api/external/v1/users/${employeeId}/mfa/reset`,
        "POST",
        {},
        null
      );
      dynamicNotification(__("Logowanie zostało zresetowane"));
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 425"
      );
      dynamicNotification(
        e.message || __("Nie udało się zresetować logowania"),
        "error"
      );
    }
  }, [employeeId]);

  const additionalButtons = useMemo(() => {
    const additionalButtons = [];
    if (data?.id && !isNew) {
      additionalButtons.push({
        id: "active",
        size: "lg",
        color: "danger",
        className: "mr-2",
        text: data.active ? __("Dezaktywuj") : __("Aktywuj"),
        onClick: () => toggleActivation(),
        disabled: blockedActivation,
        permission: employeeEmployeePermissionWrite,
      });

      if (!isAhr) {
        additionalButtons.push({
          id: "signInKC",
          size: "lg",
          color: "secondary",
          className: "mr-2",
          text: __("Zaloguj jako pracownik"),
          disabled: !data.active || blockedButtons,
          title: !data.active ? __("Pracownik musi być aktywny") : null,
          onClick: () => loginAsEmployeeKC(employeeId, setBlockedButtons),
          permission: ssoCanSwitchUser,
        });
      }
      if (!data?.lastLoginDate) {
        additionalButtons.push({
          size: "lg",
          id: "print",
          color: "secondary",
          className: "mr-2",
          text: __("Drukuj list"),
          disabled: !originalData.id || blockedButtons,
          onClick: () => downloadAttachmentFile(employeeId),
          permission: employeeEmployeePermissionWrite,
        });

        const canSendEmail =
          !!originalData.businessEmail &&
          config["email-templates/email-welcome/email-subject"] &&
          config["email-templates/email-welcome/template"];
        additionalButtons.push({
          id: "sendEmail",
          size: "lg",
          color: "secondary",
          className: "mr-2",
          text: __("Wyślij e-mail"),
          disabled: !canSendEmail || !originalData.id || blockedButtons,
          tooltip: getEmailTooltip(originalData, isAhr, config),
          onClick: () =>
            sendMessageToUser(employeeId, "email", setBlockedButtons),
          permission: employeeEmployeePermissionWrite,
          title: canSendEmail
            ? __("Wyślij email")
            : `Aby wysłać e-mail uzupełnij:
            - adres e-mail (służbowy) pracownika          
            - szablon listu powitalnego dla pracownika`,
        });

        const canSendTextMessage = !!originalData.businessPhone;
        additionalButtons.push({
          id: "sendSms",
          size: "lg",
          color: "secondary",
          className: "mr-2",
          text: __("Wyślij SMS"),
          permission: employeeEmployeePermissionWrite,
          disabled: !canSendTextMessage || !originalData.id || blockedButtons,
          tooltip: canSendTextMessage
            ? null
            : __(
                "Aby wysłać sms pracownik musi mieć zapisany służbowy numer telefonu."
              ),
          onClick: () =>
            sendMessageToUser(employeeId, "sms", setBlockedButtons),
          title: canSendTextMessage
            ? __("Wyślij SMS")
            : __(
                "Aby wysłać sms pracownik musi mieć zapisany służbowy numer telefonu."
              ),
        });
      }
      if (individualWindowsEnabled) {
        additionalButtons.push({
          id: "deleteIndividualSelectionWindows",
          size: "lg",
          color: "info",
          className: "mr-2",
          text: "Wyczyść okna wyboru",
          disabled: !hasAccessToSelectionWindowsWrite,
          onClick: () => clearIndividualWindows(),
          permission: employeeEmployeePermissionWrite,
        });
      }
    }

    return additionalButtons;
  }, [
    data,
    isNew,
    isAhr,
    originalData,
    individualWindowsEnabled,
    blockedActivation,
    blockedButtons,
    config,
    employeeId,
    hasAccessToSelectionWindowsWrite,
  ]);

  if (!active) {
    return null;
  }

  const submit = async () => {
    try {
      const dataToPatch = isAhr ? deleteOMBOnlyFields(data) : data;

      if (
        shouldDisplayNameChangeModal({
          dataToSubmit: dataToPatch,
          originalData,
          isAhr,
          notificationAfterChangeEmployeeNamesForOrderDuplicateMs,
        })
      ) {
        getInfoModal({
          onClose: () => {},
          type: messageTypes.changeFirstNameOrLastName,
        });
      }
      let response = await restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        isNew ? "/employees" : `/employees/${employeeId}`,
        isNew ? "POST" : "PATCH",
        {
          body: {
            ...dataToPatch,
            email: undefined,
            phone: undefined,
            companyId,
            supervisor: dataToPatch?.supervisor || null,
          },
        },
        dataToPatch
      );
      if (hasAccessToSelectionWindowsWrite) {
        await saveRentableGroupSelectionWindow(
          response.id,
          windowSelectionData,
          individualWindowsEnabled
        );
        response = await restApiRequest(
          EMPLOYEE_MANAGEMENT_SERVICE,
          `/employees/${employeeId === "-1" ? response.id : employeeId}`,
          "GET",
          {},
          {}
        );
        if (
          !_.isEqual(
            data?.individualWindowPopup,
            response?.individualWindowPopup
          ) ||
          !_.isEqual(
            data?.individualWindowAccount,
            response?.individualWindowAccount
          )
        ) {
          getRentableGroupSelectionWindow(response);
        }
      }

      dynamicNotification(__("Pomyślnie zapisano pracownika"));
      setIsEdited(false, () => {
        if (isNew) {
          history.push(
            isAhr
              ? `/ahr/employees/${response.id}`
              : `/company/edit/${companyId}/employee-management/employees/${response.id}`
          );
        }
      });
      setEmployeeData(response);
      updateData(response);
      updateOriginalData(response);
      setGeneratedPassword("");
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 413"
      );
      dynamicNotification(
        e.message || __("Nie udało się zapisać pracownika"),
        "error"
      );
    }
  };

  const sendActivationStatus = async (isActive, params) => {
    try {
      await restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        `/employee-status/${employeeId}`,
        "PATCH",
        {
          body: params,
        },
        null
      );

      dynamicNotification(
        isActive
          ? __("Pomyślnie dezaktywowano pracownika")
          : __("Pomyślnie aktywowano pracownika")
      );

      updateData(null);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 445"
      );
      dynamicNotification(e.message || __("Błąd pobierania danych"), "error");
    }
  };

  const confirmDeactivation = (params) => {
    const hasAnyOpenSubscriptions = checkOpenSubscriptions(subData);

    if (!hasAnyOpenSubscriptions) return false;
    getUserConfirmationPopup(
      `${__(
        "Dezaktywacja konta spowoduje rezygnację z aktywnych świadczeń abonamentowych pracownika oraz anulację świadczeń oczekujących. Rezygnacja ma skutek na ostatni dzień miesiąca, w którym konto zostanie dezaktywowane. Czy na pewno chcesz dezaktywować konto użytkownika?"
      )}
      `,
      async (confirm) => {
        // action on deactivation button
        if (params) {
          if (confirm) sendActivationStatus(data.active, params);
        } else if (!confirm) {
          // action on datepicker change
          const updatedData = { ...data };
          updatedData.activeTo = updatedData.activeToPrev;
          delete updatedData.activeToPrev;
          updateData(updatedData);
        }

        return false;
      },
      __(
        "Uwaga! Dezaktywacja konta spowoduje rezygnację z wybranych przez pracownika abonamentów!"
      )
    );

    return false;
  };

  const toggleActivation = async () => {
    try {
      const hasAnyOpenSubscriptions = checkOpenSubscriptions(subData);

      setBlockedActivation(true);

      if (data.active && hasAnyOpenSubscriptions) {
        await confirmDeactivation({});
      } else {
        await sendActivationStatus(data.active, {});
      }
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 509"
      );
      dynamicNotification(
        e.message || __("Nie udało się zapisać pracownika"),
        "error"
      );
    }
    setBlockedActivation(false);
  };

  const clearIndividualWindows = async () => {
    try {
      const params = {};
      setBlockedButtons(true);
      await restApiRequest(
        EMPLOYEE_MANAGEMENT_SERVICE,
        `/employee/${employeeId}/delete/individual-selection-window`,
        "PATCH",
        {
          body: params,
        },
        null
      );
      dynamicNotification(
        __("Usunięto indywidualne okna wyboru grup dochodowości")
      );
      updateData(null);
    } catch (e) {
      console.error(e);
      newRelicErrorReport(
        e,
        "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 540"
      );
      dynamicNotification(
        e.message ||
          __(
            "Nie udało się usunąć indywidualnych okien wyboru grup dochodowości"
          ),
        "error"
      );
    }
    setBlockedButtons(false);
  };

  const downloadAttachmentFile = async (id) => {
    setBlockedButtons(true);
    await downloadFile(
      EMPLOYEE_MANAGEMENT_SERVICE,
      `/employee/${id}/password/print`,
      uuid()
    );
    setBlockedButtons(false);
  };

  const getRentableGroupSelectionWindow = async (employeeData) => {
    const iris = [
      employeeData?.individualWindowAccount,
      employeeData?.individualWindowPopup,
    ].filter(Boolean);
    setLoadingWindowSelection(true);
    let result = [];
    if (iris.length) {
      result = await fetchRentableGroupSelectionWindow(iris);
    }
    setWindowSelectionData(
      result.map((item) => {
        const [, , warsawRangeDateAndTimeFrom] = getWarsawDate(
          item?.rangeDateFrom
        );
        const [warsawRangeDateTo] = getWarsawDate(item?.rangeDateTo);
        return {
          ...item,
          ...(warsawRangeDateAndTimeFrom
            ? { rangeDateFrom: warsawRangeDateAndTimeFrom }
            : {}),
          ...(warsawRangeDateTo ? { rangeDateTo: warsawRangeDateTo } : {}),
          windowType: item?.windowType?.toString(),
          enabled: true,
        };
      })
    );
    setIndividualWindowsEnabled(result.length > 0);
    setLoadingWindowSelection(false);
  };

  const additionalValidation = () => true;

  const formData = { ...(data || {}), individualWindowsEnabled };
  if (individualWindowsEnabled) {
    const windowTypes = [];
    windowSelectionData.forEach(({ windowType, enabled, ...windowData }) => {
      const windowTypePrefix = getWindowPrefix(windowType);
      if (enabled) {
        windowTypes.push(windowType);
      }
      Object.keys(windowData).forEach((key) => {
        if (["rangeDateFrom", "rangeDateTo"].includes(key)) {
          formData[
            `rangeDate${windowTypePrefix}${key.replace("rangeDate", "")}`
          ] = windowData[key];
        } else {
          formData[`${key}${windowTypePrefix}`] = windowData[key];
        }
      });
    });

    if (isFutureDate(formData?.rangeDateFrom)) {
      setIsEditableIndividualWindow(true);
    }
    formData.windowTypes = windowTypes;
  }
  const individualSelectionWindowPopup = Boolean(
    formData?.windowTypes?.includes(WINDOW_TYPE_POPUP)
  );
  const individualSelectionWindowAccount = Boolean(
    formData?.windowTypes?.includes(WINDOW_TYPE_ACCOUNT)
  );

  const loader = (
    <Loader
      key="selectionsWindowLoader"
      active
      type="line-scale"
      style={{ transform: "scale(0.6)" }}
    />
  );

  const automaticReplaceBenefitAfterChangeGP =
    hasAtLeastOneGroupAutoReplaceBenefitAfterChangeGP(config);

  return (
    <DataLoading
      service={EMPLOYEE_MANAGEMENT_SERVICE}
      fetchedData={isNew || data?.id}
      updateData={(result) => {
        getRentableGroupSelectionWindow(result);
        const updatedData = { ...result };
        if (!updatedData.preferredLanguage) {
          updatedData.preferredLanguage = defaultLanguage;
        }
        setWarsawTime(updatedData);
        updateOriginalData({ ...updatedData });
      }}
      mockDataEndpoint="/employee/general/edit"
      endpoint={`/employees/${employeeId}`}
      isNew={isNew}
    >
      <Form
        id="employeeGeneral"
        data={formData}
        data1="employeeData"
        config={{
          additionalValidation,
          title: `${__("Dane pracownika")}`,
          stickyTitle: true,
          onSubmit: submit,
          buttons: [
            ...additionalButtons,
            {
              id: "submit",
              size: "lg",
              color: "primary",
              className: "mr-2",
              text: "Zapisz",
              type: "submit",
              permission: employeeEmployeePermissionWrite,
            },
          ],
          defaultOnChange: onChange,
          columns: [
            {
              formGroups: [
                {
                  title: `${__("Podstawowe dane")}`,
                  formElements: [
                    {
                      id: "firstName",
                      label: "Imię:",
                      validation: [
                        "required",
                        { method: "minLength", args: [2] },
                      ],
                      type: "text",
                      allowDirtyHTML: true,
                    },
                    {
                      id: "lastName",
                      label: "Nazwisko:",
                      validation: [
                        "required",
                        { method: "minLength", args: [2] },
                      ],
                      type: "text",
                      allowDirtyHTML: true,
                    },
                    {
                      id: "username",
                      validation: [
                        "required",
                        { method: "minLength", args: [5] },
                      ],
                      props: {
                        disabled: isAhr && disableAdminEmployeeUserNameChange,
                      },
                      label: "Login:",
                      type: "text",
                      displayCondition: !isNew,
                      allowDirtyHTML: true,
                    },
                    {
                      id: "testUser",
                      label: "Testowy pracownik",
                      type: "boolean",
                      displayCondition: !isAhr,
                    },
                    {
                      id: "businessPhone",
                      label: "Numer tel. kom. (służbowy):",
                      type: "text",
                      tooltip: {
                        type: "info",
                        content: (
                          <>
                            {`${__("Wpisz numer telefonu komórkowego")},`}{" "}
                            <br />
                            {__(
                              "jeśli list powitalny z loginem i hasłem do systemu"
                            )}{" "}
                            <br />
                            {`${__("będziesz wysyłać sms-em")}.`}
                          </>
                        ),
                      },
                      validation: ["phone"],
                    },
                    {
                      id: "businessPhone2",
                      label: "Numer tel. kom. (służbowy) 2:",
                      type: "text",
                      tooltip: {
                        type: "info",
                        content: (
                          <>
                            {`${__("Wpisz numer telefonu komórkowego")},`}{" "}
                            <br />
                            {__(
                              "jeśli list powitalny z loginem i hasłem do systemu"
                            )}{" "}
                            <br />
                            {`${__("będziesz wysyłać sms-em")}.`}
                          </>
                        ),
                      },
                      validation: ["phone"],
                    },
                    {
                      id: "personalPhone",
                      label: "Numer tel. kom. (prywatny):",
                      type: "text",
                      validation: ["phone"],
                      displayCondition: !isAhr,
                      props: {
                        disabled: true,
                      },
                    },
                    {
                      id: "businessEmail",
                      label: "E-mail (służbowy):",
                      type: "text",
                      tooltip: {
                        type: "info",
                        content: (
                          <>
                            {`${__("Wpisz email")},`} <br />
                            {__(
                              "jeśli list powitalny z loginem i hasłem do systemu"
                            )}{" "}
                            <br />
                            {`${__("będziesz wysyłać e-mailowo")}.`}
                          </>
                        ),
                      },
                      validation: ["email"],
                    },
                    {
                      id: "personalEmail",
                      label: "E-mail (prywatny):",
                      type: "text",
                      validation: ["email"],
                      displayCondition: !isAhr,
                      props: {
                        disabled: true,
                      },
                    },
                    {
                      id: "pesel",
                      label: "PESEL:",
                      type: "text",
                      displayCondition: peselFunctionality,
                    },
                    {
                      id: "workPlace",
                      label: `${workPlace || "Miejsce wykonywania pracy"}:`,
                      type: "text",
                      displayCondition: mpFunctionality,
                    },
                    {
                      id: "costPlace",
                      label: `${costPlace || "Miejsce powstania kosztu"}:`,
                      type: "text",
                      displayCondition: mpkFunctionality,
                    },
                    {
                      id: "fk",
                      label: "Numer pracownika w systemie kadrowo-płacowym:",
                      type: "text",
                      validation: [
                        "required",
                        { method: "incorrectChars", args: [";?"] },
                      ],
                    },
                    {
                      id: "province",
                      label: "Województwo:",
                      type: "select",
                      options: dictionaries.get(DICTIONARY_PROVINCES),
                      tooltip: {
                        type: "info",
                        content: (
                          <>
                            {`${__("Województwo miejsca pracy")}.`} <br />
                            {__(
                              "Potrzebne do filtrowania aktualności pokazujących się pracownikowi"
                            )}{" "}
                            <br />
                            {`${__("tylko do tych z jego regionu")}.`}
                          </>
                        ),
                      },
                    },
                    {
                      component: loader,
                      displayCondition: loadingEmployeeGroup,
                    },
                    {
                      id: "employeeGroup",
                      displayCondition: !loadingEmployeeGroup,
                      label: "Grupa pracownicza:",
                      type: "select",
                      validation: ["required"],
                      options: employeeGroups,
                      onChange: async (key, value) => {
                        if (data?.rentableGroup) {
                          const previousRentableGroup =
                            getIdFromIriRentableGroup(data?.rentableGroup);
                          setLoadingEmployeeGroup(true);
                          const availableRentableGroups =
                            await fetchRentableGroupsByEmployeeGroupIri(value);
                          setLoadingEmployeeGroup(false);
                          const availableRentableGroupsIds =
                            availableRentableGroups?.flatMap(
                              (rentGroup) => rentGroup.value
                            );
                          if (availableRentableGroups) {
                            if (
                              !availableRentableGroupsIds.includes(
                                previousRentableGroup
                              )
                            ) {
                              getChangeEmployeeGroupPopup();

                              return;
                            }
                          }
                        }

                        if (!subData)
                          dynamicNotification(
                            "Nie udało się pobrać wszystkich danych",
                            "error"
                          );

                        const hasAnyOpenSubscriptions =
                          checkOpenSubscriptions(subData);

                        if (hasAnyOpenSubscriptions) {
                          getUserConfirmationPopup(
                            getChangeEmployeeGroupPopupMessage(
                              automaticReplaceBenefitAfterChangeGP,
                              enableConfigurationRentableAndOrganization
                            ),
                            (confirm) => {
                              if (confirm) {
                                onChange(key, value);
                              }
                            },
                            getChangeEmployeeGroupPopupTitle(
                              automaticReplaceBenefitAfterChangeGP,
                              enableConfigurationRentableAndOrganization
                            )
                          );
                        }

                        if (!hasAnyOpenSubscriptions && subData) {
                          onChange(key, value);
                        }
                      },
                    },
                    {
                      id: "department",
                      label: "Dział:",
                      type: "autocomplete",
                      displayCondition: departmentFunctionality,
                      options: departments,
                      props: {
                        disabled: !hasDepartmentWritePermission,
                      },
                    },
                    {
                      id: "supervisor",
                      label: "Przełożony:",
                      type: "asyncAutocomplete",
                      displayCondition: departmentFunctionality,
                      fetchOptions: getEmployeesOptionsFetchMethod(
                        data?.supervisor,
                        { companyId: data?.companyId }
                      ),
                      props: {
                        disabled: !hasDepartmentWritePermission,
                      },
                    },
                  ],
                },
              ],
            },
            {
              formGroups: [
                {
                  title: `${__("Grupy dochodowości pracownika")}`,
                  formElements: [
                    {
                      id: "rentableGroup",
                      label: "Grupa dochodowości:",
                      type: "select",
                      options: rentableGroups,
                      onChange: async (key, value) => {
                        if (!subData)
                          dynamicNotification(
                            "Nie udało się pobrać wszystkich danych",
                            "error"
                          );

                        const hasAnyOpenSubscriptions =
                          checkOpenSubscriptions(subData);

                        if (
                          (hasAnyOpenSubscriptions &&
                            automaticReplaceBenefitAfterChangeGP &&
                            enableConfigurationRentableAndOrganization) ||
                          (hasAnyOpenSubscriptions &&
                            !automaticReplaceBenefitAfterChangeGP &&
                            enableConfigurationRentableAndOrganization)
                        ) {
                          getUserConfirmationPopup(
                            getChangeEmployeeGroupPopupMessage(
                              automaticReplaceBenefitAfterChangeGP,
                              enableConfigurationRentableAndOrganization
                            ),
                            (confirm) => {
                              if (confirm) {
                                onChange(key, value);
                              }
                            },
                            getChangeEmployeeGroupPopupTitle(
                              automaticReplaceBenefitAfterChangeGP,
                              enableConfigurationRentableAndOrganization
                            )
                          );
                        } else {
                          onChange(key, value);
                        }
                      },
                    },
                    {
                      layout: LAYOUT_ONE_COLUMN,
                      border: individualWindowsEnabled,
                      formElements: [
                        {
                          component: loader,
                          displayCondition: loadingWindowSelection,
                        },
                        {
                          component: (
                            <Alert
                              color="secondary"
                              key="noAccessToEditSelectionWindowAlert"
                            >
                              {__(
                                "Nie masz uprawnień do edycji okien wyboru grup dochodowości"
                              )}
                            </Alert>
                          ),
                          displayCondition:
                            !hasAccessToSelectionWindowsWrite &&
                            !loadingWindowSelection,
                        },
                        {
                          id: "individualWindowsEnabled",
                          label:
                            "Włącz indywidualne okno wyboru grup dochodowości",
                          onChange: (key, value) =>
                            setIndividualWindowsEnabled(value),
                          type: "boolean",
                          props: {
                            disabled: !hasAccessToSelectionWindowsWrite,
                          },
                          displayCondition: !loadingWindowSelection,
                        },
                        {
                          id: "windowTypes",
                          label: `${__("Typ okna")}`,
                          type: "checkbox",
                          displayCondition: individualWindowsEnabled,
                          onChange: (key, value) => enableWindow(value),
                          validation: ["required"],
                          props: {
                            disabled:
                              !hasAccessToSelectionWindowsWrite ||
                              !isEditableIndividualWindow,
                          },
                          options: translateLabels(windowTypeOptions),
                        },
                        {
                          layout: LAYOUT_ONE_COLUMN,
                          border: individualSelectionWindowPopup,
                          formElements: [
                            {
                              id: "individualWindowTypeTitle",
                              label: `${__("Okno wyboru na popupie")}`,
                              type: "title",
                              displayCondition: individualSelectionWindowPopup,
                            },
                            {
                              id: "activationModePopup",
                              label: `${__("Typ aktywacji")}:`,
                              type: "radio",
                              validation: ["required"],
                              props: {
                                disabled:
                                  !hasAccessToSelectionWindowsWrite ||
                                  !isEditableIndividualWindow,
                              },
                              onChange: (key, value) =>
                                onChangeWindow(
                                  "activationMode",
                                  value,
                                  WINDOW_TYPE_POPUP
                                ),
                              displayCondition: individualSelectionWindowPopup,
                              options: translateLabels(
                                individualActivationModeOptionPopup
                              ),
                            },
                            {
                              id: "rangeDatePopup",
                              type: "dateRange",
                              data1: "rangePopup",
                              onChange: (key, value) =>
                                onChangeWindow(
                                  "rangeDate",
                                  value,
                                  WINDOW_TYPE_POPUP
                                ),
                              validation: ["rangeRequiredBoth"],
                              displayCondition: individualSelectionWindowPopup,
                              props: {
                                disabled:
                                  !hasAccessToSelectionWindowsWrite ||
                                  !isEditableIndividualWindow,
                                from: {
                                  dateFormat: "dd/MM/yyyy HH:mm",
                                  showTimeSelect: true,
                                },
                              },
                              depends: {
                                field: "activationModePopup",
                                value: ACTIVATION_MODE_RANGE,
                              },
                            },
                            {
                              layout: LAYOUT_TWO_COLUMNS,
                              formElements: [
                                {
                                  id: "sendingEmailsPopup",
                                  type: "boolean",
                                  isCheckbox: true,
                                  displayCondition:
                                    individualSelectionWindowPopup &&
                                    !isActivationModeInfinity(
                                      windowSelectionData,
                                      "1"
                                    ),
                                  props: {
                                    disabled:
                                      !hasAccessToSelectionWindowsWrite ||
                                      !isEditableIndividualWindow,
                                  },
                                  onChange: (key, value) =>
                                    onChangeWindow(
                                      "sendingEmails",
                                      value,
                                      WINDOW_TYPE_POPUP
                                    ),
                                  label:
                                    "Wyślij e-mail X dni przed zakończeniem typu okna",
                                },
                                {
                                  id: "defaultGroupOnEndPopup",
                                  type: "boolean",
                                  isCheckbox: true,
                                  displayCondition:
                                    individualSelectionWindowPopup,
                                  props: {
                                    disabled:
                                      !hasAccessToSelectionWindowsWrite ||
                                      !isEditableIndividualWindow,
                                  },
                                  onChange: (key, value) =>
                                    onChangeWindow(
                                      "defaultGroupOnEnd",
                                      value,
                                      WINDOW_TYPE_POPUP
                                    ),
                                  label:
                                    "Na zakończenie tego okna przypisz automatycznie domyślną grupę dochodowości",
                                },
                                {
                                  id: "daysSendEmailBeforeEndPopup",
                                  type: "text",
                                  valueFormatter: "integer",
                                  suffix: `${__("dni")}`,
                                  validation: ["required"],
                                  props: {
                                    disabled:
                                      !hasAccessToSelectionWindowsWrite ||
                                      !isEditableIndividualWindow,
                                  },
                                  displayCondition:
                                    individualSelectionWindowPopup,
                                  onChange: (key, value) =>
                                    onChangeWindow(
                                      "daysSendEmailBeforeEnd",
                                      value,
                                      WINDOW_TYPE_POPUP
                                    ),
                                  depends: {
                                    field: "sendingEmailsPopup",
                                    value: true,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          layout: LAYOUT_ONE_COLUMN,
                          border: individualSelectionWindowAccount,
                          formElements: [
                            {
                              id: "individualWindowTypeTitle",
                              label: "Okno wyboru w profilu",
                              type: "title",
                              displayCondition:
                                individualSelectionWindowAccount,
                            },
                            {
                              id: "activationModeAccount",
                              label: "Typ aktywacji:",
                              type: "radio",
                              validation: ["required"],
                              props: {
                                disabled:
                                  !hasAccessToSelectionWindowsWrite ||
                                  !isEditableIndividualWindow,
                              },
                              onChange: (key, value) =>
                                onChangeWindow(
                                  "activationMode",
                                  value,
                                  WINDOW_TYPE_ACCOUNT
                                ),
                              displayCondition:
                                individualSelectionWindowAccount,
                              options: translateLabels(
                                individualActivationModeOptions
                              ),
                            },
                            {
                              id: "rangeDateAccount",
                              type: "dateRange",
                              data1: "rangeAccount",
                              validation: ["rangeRequiredBoth"],
                              displayCondition:
                                individualSelectionWindowAccount,
                              props: {
                                disabled:
                                  !hasAccessToSelectionWindowsWrite ||
                                  !isEditableIndividualWindow,
                                from: {
                                  dateFormat: "dd/MM/yyyy HH:mm",
                                  showTimeSelect: true,
                                },
                              },
                              onChange: (key, value) =>
                                onChangeWindow(
                                  "rangeDate",
                                  value,
                                  WINDOW_TYPE_ACCOUNT
                                ),
                              depends: {
                                field: "activationModeAccount",
                                value: ACTIVATION_MODE_RANGE,
                              },
                            },
                            {
                              layout: LAYOUT_TWO_COLUMNS,
                              formElements: [
                                {
                                  id: "sendingEmailsAccount",
                                  type: "boolean",
                                  isCheckbox: true,
                                  props: {
                                    disabled:
                                      !hasAccessToSelectionWindowsWrite ||
                                      !isEditableIndividualWindow,
                                  },
                                  onChange: (key, value) =>
                                    onChangeWindow(
                                      "sendingEmails",
                                      value,
                                      WINDOW_TYPE_ACCOUNT
                                    ),
                                  displayCondition:
                                    individualSelectionWindowAccount &&
                                    !isActivationModeInfinity(
                                      windowSelectionData,
                                      "2"
                                    ),
                                  label:
                                    "Wyślij e-mail X dni przed zakończeniem typu okna",
                                },
                                {
                                  id: "defaultGroupOnEndAccount",
                                  type: "boolean",
                                  isCheckbox: true,
                                  displayCondition:
                                    individualSelectionWindowAccount,
                                  props: {
                                    disabled:
                                      !hasAccessToSelectionWindowsWrite ||
                                      !isEditableIndividualWindow,
                                  },
                                  onChange: (key, value) =>
                                    onChangeWindow(
                                      "defaultGroupOnEnd",
                                      value,
                                      WINDOW_TYPE_ACCOUNT
                                    ),
                                  label:
                                    "Na zakończenie tego okna przypisz automatycznie domyślną grupę dochodowości",
                                },
                                {
                                  id: "daysSendEmailBeforeEndAccount",
                                  displayCondition:
                                    individualSelectionWindowAccount,
                                  type: "text",
                                  valueFormatter: "integer",
                                  suffix: `${__("dni")}`,
                                  props: {
                                    disabled:
                                      !hasAccessToSelectionWindowsWrite ||
                                      !isEditableIndividualWindow,
                                  },
                                  onChange: (key, value) =>
                                    onChangeWindow(
                                      "daysSendEmailBeforeEnd",
                                      value,
                                      WINDOW_TYPE_ACCOUNT
                                    ),
                                  validation: ["required"],
                                  depends: {
                                    field: "sendingEmailsAccount",
                                    value: true,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      id: "organizationUnit",
                      label: `${
                        organizationUnit || "Jednostka organizacyjna"
                      }:`,
                      type: "select",
                      options: organizationUnits,
                      validation: enableOrganizationUnits ? ["required"] : null,
                      onChange: async (key, value) => {
                        if (!subData)
                          dynamicNotification(
                            "Nie udało się pobrać wszystkich danych",
                            "error"
                          );

                        const hasAnyOpenSubscriptions =
                          checkOpenSubscriptions(subData);

                        const getPopupAfterChangeOU = () => {
                          if (
                            isAhr &&
                            displayPopupToAhrAfterChangeEmployeeOrganizationUnit
                          ) {
                            getInfoModal({
                              onClose: () => {
                                onChange(key, value);
                              },
                              type: messageTypes.changeOrganizationUnit,
                            });
                          }
                        };

                        if (
                          (hasAnyOpenSubscriptions &&
                            automaticReplaceBenefitAfterChangeGP &&
                            enableConfigurationRentableAndOrganization) ||
                          (hasAnyOpenSubscriptions &&
                            !automaticReplaceBenefitAfterChangeGP &&
                            enableConfigurationRentableAndOrganization)
                        ) {
                          getUserConfirmationPopup(
                            getChangeEmployeeGroupPopupMessage(
                              automaticReplaceBenefitAfterChangeGP,
                              enableConfigurationRentableAndOrganization
                            ),
                            (confirm) => {
                              if (confirm) {
                                getPopupAfterChangeOU();
                                onChange(key, value);
                              }
                            },
                            getChangeEmployeeGroupPopupTitle(
                              automaticReplaceBenefitAfterChangeGP,
                              enableConfigurationRentableAndOrganization
                            )
                          );
                        } else {
                          getPopupAfterChangeOU();
                          onChange(key, value);
                        }
                      },
                    },
                  ],
                },
                {
                  title: `${__("Zarządzanie kontem użytkownika")}`,
                  formElements: [
                    {
                      type: "title",
                      id: "active",
                      label: data?.active
                        ? "Konto jest aktywne"
                        : "Konto jest nieaktywne",
                    },
                    {
                      layout: LAYOUT_ONE_COLUMN,
                      formElements: [
                        {
                          id: "localActiveFrom",
                          label: "Konto aktywne od:",
                          type: "date",
                          validation: ["required"],
                          onChange: (id, date) => onChangeActiveFrom(id, date),
                          tooltip: {
                            type: "info",
                            content: (
                              <>
                                {__(
                                  "Uwaga! Data wyświetla się w polskiej strefie czasowej"
                                )}
                              </>
                            ),
                          },
                        },
                      ],
                      layoutConfig: {
                        sm: 6,
                      },
                    },
                    {
                      layout: LAYOUT_TWO_COLUMNS,
                      formElements: [
                        // {
                        //   label: data?.activeFrom ? 'Godzina:' : '',
                        //   component: data?.activeFrom ? (
                        //     <Alert
                        //       key="activeFromAlert"
                        //       className="employeeAlert"
                        //       color="warning"
                        //     >
                        //       { getTimeFormat(data?.activeFrom) }
                        //     </Alert>
                        //   ) : <></>,
                        // },
                        {
                          id: "localActiveTo",
                          label: "Dezaktywacja konta z dniem:",
                          type: "date",
                          onChange: (id, date) => onChangeDeactive(id, date),
                          confirmChange: confirmDeactivation,
                          tooltip: {
                            type: "info",
                            content: (
                              <>
                                {`${__(
                                  "Z datą dezaktywacji konto pracownika zostanie zamknięte"
                                )}.`}{" "}
                                <br />{" "}
                                {`${__(
                                  "Konto zezmienionym statusem będzie nadal widoczne na liście pracowników oraz w historii operacji."
                                )}`}
                                <br />
                                <br />
                                {/* eslint-disable-next-line max-len */}
                                {`${__(
                                  "Uwaga! Jeżeli podczas ustawienia daty dezaktywacji pracownika zostały zrezygnowane świadczenia abonamentowe"
                                )},`}
                                <br />
                                {`${__(
                                  "to zmiana lub usunięcie tej daty nie modyfikuje dat rezygnacji tych świadczeń"
                                )}.`}
                                <br />
                                <br />
                                {__(
                                  "Uwaga! Data wyświetla się w polskiej strefie czasowej"
                                )}
                              </>
                            ),
                          },
                        },
                        {
                          label:
                            data?.localActiveToTime && !data.activeToDate
                              ? "Godzina:"
                              : "",
                          component:
                            data?.localActiveToTime && !data.activeToDate ? (
                              <Alert
                                key="activeToAlert"
                                className="employeeAlert"
                                color="warning"
                              >
                                {data.localActiveToTime}
                              </Alert>
                            ) : (
                              <></>
                            ),
                        },
                      ],
                    },
                    {
                      id: "password",
                      label: "Hasło:",
                      type: "password",
                      onChange: (key, value) => {
                        setGeneratedPassword("");
                        onChange(key, value);
                      },
                      displayCondition: !isNew,
                      validation: ["password"],
                    },
                    {
                      id: "password2",
                      label: "Powtórz hasło:",
                      type: "password",
                      displayCondition: !isNew,
                      validation: [
                        {
                          method: "mustBeEqual",
                          args: [
                            data && data.password,
                            "Hasła nie są takie same",
                          ],
                        },
                      ],
                      props: {
                        previewToggle: false,
                      },
                    },
                    {
                      id: "emailType",
                      label: "Wysyłaj na e-mail:",
                      type: "select",
                      options: emailTypesOptions,
                      displayCondition:
                        originalData.businessEmail &&
                        originalData.personalEmail,
                    },
                    {
                      id: "generatePassword",
                      label: hasEmail
                        ? "Wyślij hasło na e-mail użytkownika"
                        : "Generuj hasło",
                      type: "button",
                      component: (
                        <div key="generate_passowrd">
                          <Button
                            color="secondary"
                            className="mr-1"
                            data-1="generatePassword"
                            disabled={!hasWritePermission}
                            onClick={generateAndSendPassword}
                          >
                            {hasEmail
                              ? __("Wyślij hasło na e-mail użytkownika")
                              : __("Generuj hasło")}
                          </Button>
                          <DefaultTooltip
                            content={
                              hasEmail ? (
                                <>
                                  {__(
                                    "Jeżeli nie wybrałeś na jaki adres e-mail ma zostać wysłane nowe hasło, wysyłka hasła jest realizowana w następujący sposób: w pierwszej kolejności na adres prywatny, a jeżeli adres prywatny nie jest podany, hasło zostanie wysłane na adres służbowy."
                                  )}
                                </>
                              ) : (
                                <>
                                  {__(
                                    "Wygeneruj nowe hasło, jeśli pracownik zapomniał"
                                  )}{" "}
                                  <br />{" "}
                                  {`${__(
                                    "poprzedniego i nie jest w stanie samodzielnie nadać nowego"
                                  )}.`}
                                  {__(
                                    "Aby zapisać nowe hasło kliknij w przycisk “Zapisz”."
                                  )}
                                </>
                              )
                            }
                            id="generate_password_tooltip"
                          />
                          {generatedPassword ? (
                            <span
                              className="ml-1"
                              data-t1="generatedPassword"
                              data-t2={generatedPassword}
                            >
                              {__("Wygenerowane hasło")}: {generatedPassword}
                            </span>
                          ) : null}
                        </div>
                      ),
                      displayCondition: !isNew,
                    },
                    {
                      id: "resetPassword",
                      label: "Reset logowania",
                      type: "button",
                      component: (
                        <div
                          key="reset_password"
                          style={{ paddingTop: "10px" }}
                        >
                          <Button
                            color="secondary"
                            className="mr-1"
                            data-1="resetPassword"
                            disabled={!hasWritePermission}
                            onClick={() =>
                              getUserConfirmationPopup(
                                __(
                                  "Czy na pewno chcesz zresetować użytkownikowi logowanie dwuskładnikowe?"
                                ),
                                (confirm) => confirm && resetPassword(),
                                __("Reset logowania")
                              )
                            }
                          >
                            {__("Reset logowania")}
                          </Button>
                        </div>
                      ),
                      displayCondition:
                        !isNew && hasOmbTwoFactorLoginResetPermission,
                    },
                    {
                      label:
                        'Hasło do systemu zostanie wygenerowane po kliknięciu na przycisk "Wyślij e-mail" lub "Wyślij SMS".',
                      type: "title",
                      displayCondition: isNew,
                    },
                  ],
                },
                isApplicable && {
                  title: `${__("Dostawca tożsamości")}`,
                  formElements: [
                    {
                      type: "title",
                      id: "identityProviderTitle",
                      label: "Zarządzaj konfiguracją dostawcy tożsamości",
                    },
                    {
                      id: "identityProviderEnabled",
                      // id: "individualWindowsEnabled",
                      label:
                        "Zezwalaj na przekierowania do dostawcy tożsamości (IdP).",
                      type: "boolean",
                      onChange: () => switchIdentityProvider(),
                      value: !formerIdp,
                    },
                  ],
                },
              ],
            },
          ],
        }}
      />
    </DataLoading>
  );
}

const sendMessageToUser = async (employeeId, type, setBlockedButtons) => {
  try {
    setBlockedButtons(true);
    await restApiRequest(
      EMPLOYEE_MANAGEMENT_SERVICE,
      `/employee/${employeeId}/password/${type}`,
      "GET",
      {},
      {}
    );
    const message =
      type === "sms"
        ? "Wysłano SMS do pracownika."
        : "Wysłano e-mail do pracownika.";
    dynamicNotification(__(message));
  } catch (e) {
    console.error(e);
    newRelicErrorReport(
      e,
      "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 1560"
    );
    const message =
      type === "sms"
        ? "Nie udało się wysłać wiadomości SMS."
        : "Nie udało się wysłać wiadomości e-mail.";
    dynamicNotification(e.message || __(message), "error");
  }
  setBlockedButtons(false);
};

const loginAsEmployeeKC = async (employeeId, setBlockedButtons) => {
  try {
    setBlockedButtons(true);
    await restApiRequest(
      EMPLOYEE_MANAGEMENT_SERVICE,
      `/employee/${employeeId}/impersonate`,
      "GET",
      {},
      {}
    );
    const message = "Loguję jako...";
    dynamicNotification(__(message));
    clearSessionData();
    window.location.href = "/magento/logout";
  } catch (e) {
    console.error(e);
    newRelicErrorReport(
      e,
      "Pages/Company/EmployeeManagement/CompanyEmployees/Edit/General/index.js - 1589"
    );
    const message = "Nie można zalogować się na tego użytkownika.";
    dynamicNotification(e.message || __(message), "error");
  }
  setBlockedButtons(false);
};

General.propTypes = {
  active: PropTypes.bool,
  companyId: PropTypes.string.isRequired,
  setEmployeeData: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  setIsEdited: PropTypes.func,
  isNew: PropTypes.bool,
  employeeData: PropTypes.shape({
    individualWindowAccount: PropTypes.bool,
    individualWindowPopup: PropTypes.bool,
    activeTo: PropTypes.string,
    activeFrom: PropTypes.string,
  }),
};

General.defaultProps = {
  isNew: false,
  active: false,
  employeeData: {},
  setIsEdited: () => {},
};
